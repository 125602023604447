import { getCookie } from "../AppContent";
import {
  ArticlesOfAssociation,
  ArticlesOfAssociationApartments,
  ArticlesOfAssociationBaseInfo,
  ArticlesOfAssociationCommercialSpaces,
  FinancialStatement,
  MaintenanceNeedsAssessment
} from "../HouseMap/types";
import { BuildingWithConvertedAddresses } from "../HouseMap/types";
import { DocumentAndFileName } from "./FilesDropBox";

enum DocumentType {
  ArticlesOfAssociationBaseInfo = "articles_of_association_base_info",
  ArticlesOfAssociation = "articles_of_association",
  ArticlesOfAssociationApartments = "articles_of_association_apartments",
  ArticlesOfAssociationCommercialSpaces = "articles_of_association_commercial_spaces",
  FinancialStatement = "financial_statement",
  MaintenanceNeedsAssessment = "maintenance_needs_assessment"
}

type AnalyzedReportSections = {
  document_type: DocumentType;
  analyzed_document?:
    | string
    | ArticlesOfAssociation
    | ArticlesOfAssociationBaseInfo
    | ArticlesOfAssociationApartments
    | ArticlesOfAssociationCommercialSpaces
    | FinancialStatement
    | MaintenanceNeedsAssessment;
};

type SubmitReportDocuments = {
  selectedBuilding: BuildingWithConvertedAddresses;
  documentsAndFileNames: DocumentAndFileName[];
  setIsUploading: (isUploading: boolean) => void;
  setAnalyzedArticlesOfAssociationBaseInfo: (
    info: ArticlesOfAssociationBaseInfo
  ) => void;
  setAnalyzedArticlesOfAssociationApartments: (
    apartments: ArticlesOfAssociationApartments
  ) => void;
  setAnalyzedArticlesOfAssociation: (analyysi: string) => void;
  setAnalyzedArticlesOfAssociationCommercialSpaces: (
    commercialSpaces: ArticlesOfAssociationCommercialSpaces
  ) => void;
  setAnalyzedFinancialStatement: (analyysi: string) => void;
  setAnalyzedMaintenanceNeedsAssessment: (analyysi: string) => void;
};

type TaskResponse = {
  task_id: string;
  status: "pending" | "processing" | "completed" | "failed";
  data?: AnalyzedReportSections[];
  message?: string;
};

const POLLING_INTERVAL = 3000; // 3 seconds
const MAX_POLLING_ATTEMPTS = 400; // 400 * 3 seconds = 20 minutes maximum polling time

export const submitReportDocuments = async ({
  selectedBuilding,
  documentsAndFileNames,
  setIsUploading,
  setAnalyzedArticlesOfAssociationBaseInfo,
  setAnalyzedArticlesOfAssociationApartments,
  setAnalyzedArticlesOfAssociation,
  setAnalyzedArticlesOfAssociationCommercialSpaces,
  setAnalyzedFinancialStatement,
  setAnalyzedMaintenanceNeedsAssessment
}: SubmitReportDocuments) => {
  setIsUploading(true);

  try {
    // Save building data to database
    const saveBuildingDataResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/save-building`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": getCookie("csrf_access_token")
        },
        body: JSON.stringify({
          selected_building: selectedBuilding
        })
      }
    );

    // Initial request to start processing
    const initialResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/report`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": getCookie("csrf_access_token")
        },
        body: JSON.stringify({
          documents: documentsAndFileNames.map(documentAndFileName => ({
            pdf_base64_encoded: documentAndFileName.document,
            file_name: documentAndFileName.fileName
          })),
          selected_building: selectedBuilding
        })
      }
    );

    const { instance_id } = await initialResponse.json();
    let attempts = 0;

    // Polling function
    const pollStatus = async (): Promise<void> => {
      attempts++;

      if (attempts > MAX_POLLING_ATTEMPTS) {
        setIsUploading(false);
        throw new Error("Maximum polling attempts reached");
      }

      const statusResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/analysis-status`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": getCookie("csrf_access_token")
          },
          body: JSON.stringify({
            instance_id: instance_id
          })
        }
      );

      const result: TaskResponse = await statusResponse.json();

      switch (result.status) {
        case "completed":
          if (result.data) {
            // Process the completed data
            result.data.forEach(data => {
              if (data.analyzed_document) {
                if (
                  data.document_type ===
                  DocumentType.ArticlesOfAssociationBaseInfo
                ) {
                  setAnalyzedArticlesOfAssociationBaseInfo(
                    data.analyzed_document as ArticlesOfAssociationBaseInfo
                  );
                } else if (
                  data.document_type === DocumentType.ArticlesOfAssociation
                ) {
                  setAnalyzedArticlesOfAssociation(
                    data.analyzed_document as string
                  );
                } else if (
                  data.document_type ===
                  DocumentType.ArticlesOfAssociationApartments
                ) {
                  setAnalyzedArticlesOfAssociationApartments(
                    data.analyzed_document as ArticlesOfAssociationApartments
                  );
                } else if (
                  data.document_type ===
                  DocumentType.ArticlesOfAssociationCommercialSpaces
                ) {
                  setAnalyzedArticlesOfAssociationCommercialSpaces(
                    data.analyzed_document as ArticlesOfAssociationCommercialSpaces
                  );
                } else if (
                  data.document_type === DocumentType.FinancialStatement
                ) {
                  setAnalyzedFinancialStatement(
                    data.analyzed_document as string
                  );
                } else if (
                  data.document_type === DocumentType.MaintenanceNeedsAssessment
                ) {
                  setAnalyzedMaintenanceNeedsAssessment(
                    data.analyzed_document as string
                  );
                }
              }
            });
            setIsUploading(false);
          }
          break;

        case "failed":
          setIsUploading(false);
          console.error("Analysis failed:", result.message);
          break;

        case "pending":
        case "processing":
          // Continue polling after delay
          await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));
          await pollStatus();
          break;
      }
    };

    // Start polling
    await pollStatus();
  } catch (error) {
    setIsUploading(false);
    console.error("Error:", error);
  }
};
