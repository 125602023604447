import React from "react";
import "./SignupLogin.scss";

type SignupLoginProps = {
  toggleLoginModal: () => void;
  isLoggedIn: boolean;
};

const SignupLogin = ({ toggleLoginModal, isLoggedIn }: SignupLoginProps) => (
  <div className="signup-login" onClick={toggleLoginModal}>
    <div className="user-icon" />
    <div
      className={`signup-login__text ${
        isLoggedIn ? "signup-login__text--logged-in" : ""
      }`}
    >
      {isLoggedIn ? (
        <>
          <span>Kirjaudu</span> <span>ulos</span>
        </>
      ) : (
        <span>Kirjaudu</span>
      )}
    </div>
  </div>
);

export default SignupLogin;
