export const fetchApartmentsPending = () => ({
  type: "FETCH_APARTMENTS_PENDING"
});

export const fetchApartmentsSuccess = apartments => ({
  type: "FETCH_APARTMENTS_SUCCESS",
  payload: { apartments }
});

export const fetchApartmentsError = error => ({
  type: "FETCH_APARTMENTS_ERROR",
  error
});

export const setMapCenter = coordinates => ({
  type: "SET_MAP_CENTER",
  payload: { coordinates }
});

export const selectBuilding = address => ({
  type: "SELECT_BUILDING",
  payload: { address }
});

export const toggleLike = () => ({
  type: "TOGGLE_LIKE"
});

export const likeApartment = index => ({
  type: "LIKE_APARTMENT",
  payload: { index }
});

export const selectApartment = id => ({
  type: "SELECT_APARTMENT",
  payload: { id }
});
