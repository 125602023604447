import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import initialState from "./initialState";
import rootReducer from "../reducers/rootReducer";

const middlewares = [thunk];
const configureStore = () =>
  createStore(rootReducer, initialState, applyMiddleware(...middlewares));

export default configureStore;
