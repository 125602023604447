import Config from "../config/Config";

import {
  fetchApartmentsPending,
  fetchApartmentsSuccess,
  fetchApartmentsError
} from "../state/actions/Actions";

const url = Config.APARTMENTS_URL;

const fetchApartments = () => {
  return dispatch => {
    dispatch(fetchApartmentsPending());
    fetch(url, {
      mode: "no-cors"
    })
      .then(res => res.text())
      .then(data => {
        let lines = data.split("\n");
        let fieldNames = lines[0].split(",");

        lines.shift();
        let buildings = {};
        for (let ii in lines) {
          const line = lines[ii];
          const cols = line.split(",");
          if (cols[0].length > 0) {
            let apartment = {};
            for (let nc in cols) {
              let content = cols[nc];
              let fieldname = fieldNames[nc];
              apartment[fieldname] = content;
            }

            let addressLine =
              apartment.Address + "|" + apartment["street number"];

            if (buildings.hasOwnProperty(addressLine)) {
              buildings[addressLine].push(apartment);
            } else {
              let addressEnty = [];
              addressEnty.push(apartment);
              buildings[addressLine] = addressEnty;
            }
          }
        }

        data = buildings;
        dispatch(fetchApartmentsSuccess(data));
        return data;
      })
      .catch(error => {
        dispatch(fetchApartmentsError(error));
      });
  };
};

export default fetchApartments;
