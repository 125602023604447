import React, { useState, useRef, useEffect } from "react";
import "./SignupLoginModal.scss";
import { getCookie } from "../AppContent";

type Props = {
  toggleSignupLoginModalVisible: () => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  isLoggedIn: boolean;
  onLoginSuccess: (expiresIn: number) => void; // Add this new prop
};

const SignupLoginModal = (props: Props) => {
  const {
    toggleSignupLoginModalVisible,
    setIsLoggedIn,
    isLoggedIn,
    onLoginSuccess
  } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showLoginView, setShowLoginView] = useState(false);
  const [showSignupView, setShowSignupView] = useState(false);
  const [
    showEmailVerificationCodeInputView,
    setShowEmailVerificationCodeInputView
  ] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [loginError, setLoginError] = useState(""); // Add this line
  const [isCheckingUser, setIsCheckingUser] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
  const [passwordError, setPasswordError] = useState(""); // Add this line
  const [emailError, setEmailError] = useState(""); // Add this line
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [verificationError, setVerificationError] = useState(""); // Add this line

  // Add these references
  const initialEmailInputRef = useRef<HTMLInputElement>(null);
  const loginPasswordInputRef = useRef<HTMLInputElement>(null);
  const signupEmailInputRef = useRef<HTMLInputElement>(null);
  const signupPasswordInputRef = useRef<HTMLInputElement>(null);
  const verificationCodeRef = useRef<HTMLInputElement>(null);

  // Add useEffect hooks to set focus when views change
  useEffect(() => {
    if (
      !showLoginView &&
      !showSignupView &&
      !showEmailVerificationCodeInputView
    ) {
      // Initial email input view
      if (initialEmailInputRef.current) {
        initialEmailInputRef.current.focus();
      }
    }
  }, [showLoginView, showSignupView, showEmailVerificationCodeInputView]);

  useEffect(() => {
    if (showLoginView && loginPasswordInputRef.current) {
      loginPasswordInputRef.current.focus();
    }
  }, [showLoginView]);

  useEffect(() => {
    if (showSignupView && signupEmailInputRef.current) {
      signupEmailInputRef.current.focus();
    }
  }, [showSignupView]);

  useEffect(() => {
    if (showEmailVerificationCodeInputView && verificationCodeRef.current) {
      verificationCodeRef.current.focus();
    }
  }, [showEmailVerificationCodeInputView]);

  const validatePassword = (password: string) => {
    if (password.length < 8) {
      return "Salasanan täytyy olla vähintään 8 merkkiä pitkä.";
    }
    if (!/[A-Z]/.test(password)) {
      return "Salasanan täytyy sisältää vähintään yksi iso kirjain.";
    }
    if (!/[a-z]/.test(password)) {
      return "Salasanan täytyy sisältää vähintään yksi pieni kirjain.";
    }
    if (!/[0-9]/.test(password)) {
      return "Salasanan täytyy sisältää vähintään yksi numero.";
    }
    return "";
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) ? "" : "Virheellinen sähköpostiosoite.";
  };

  const handleSubmitCheckIfUserExists = async () => {
    setIsCheckingUser(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/check-if-user-exists?email=${email}`,
        {
          method: "GET"
        }
      );

      if (response.ok) {
        const data = await response.json();
        // Handle successful response
        if (data.exists) {
          setShowLoginView(true);
        } else {
          setShowSignupView(true);
        }
      } else {
        // Handle error response
        console.error("Email check failed");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsCheckingUser(false);
    }
  };

  const handleSubmitLogout = async () => {
    setIsLoggingOut(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRF-TOKEN": getCookie("csrf_access_token")
        }
      });

      if (response.ok) {
        setIsLoggedIn(false);
        toggleSignupLoginModalVisible();
        setIsLoggingOut(false);
      } else {
        setIsLoggingOut(false);
        console.error("Logout failed");
      }
    } catch (error) {
      setIsLoggingOut(false);
      console.error("Error:", error);
    }
  };

  const handleSubmitLogin = async () => {
    setIsLoggingIn(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": getCookie("csrf_access_token")
        },
        body: JSON.stringify({ email, password }),
        credentials: "include"
      });

      if (response.ok) {
        const data = await response.json();
        setLoginError(""); // Clear any previous error message
        onLoginSuccess(data.expires_in); // Call this function with the expires_in value
        toggleSignupLoginModalVisible(); // Close the modal after successful login
      } else {
        // Handle error response
        console.error("Login failed");
        setLoginError("Tarkista salasana."); // Set error message
      }
    } catch (error) {
      console.error("Error:", error);
      setLoginError("Hups! Jotain meni pieleen."); // Set error message for unexpected errors
    } finally {
      setIsLoggingIn(false);
    }
  };

  const handleSubmitSignup = async () => {
    setIsSigningUp(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email, password })
      });

      if (response.ok) {
        const data = await response.json();
        // Handle successful response
        setShowSignupView(false);
        setPassword("");
        setShowEmailVerificationCodeInputView(true);
      } else {
        const errorData = await response.json();
        if (errorData.error === "user_exists") {
          setShowSignupView(false);
          setPassword("");
          setShowLoginView(true);
          setLoginError(
            "Näyttää siltä, että sinulla on jo tunnus. Kirjaudu sisään."
          );
        } else {
          console.error("Signup failed");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSigningUp(false);
    }
  };

  const handleSubmitEmailVerificationCode = async () => {
    setIsVerifyingEmail(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/confirm-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": getCookie("csrf_access_token")
          },
          body: JSON.stringify({ email, verification_code: verificationCode }),
          credentials: "include"
        }
      );

      if (response.ok) {
        const data = await response.json();
        setIsLoggedIn(true);
        toggleSignupLoginModalVisible();
        onLoginSuccess(data.expires_in);
      } else {
        const errorData = await response.json();
        if (errorData.error === "invalid_code") {
          setVerificationError("Invalid verification code. Please try again."); // Set error message
        } else {
          console.error("Email confirmation code failed");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsVerifyingEmail(false);
    }
  };

  return (
    <div className="signup-login-modal-overlay">
      <div
        className={`signup-login-modal ${
          showSignupView ? "signup-login-modal--signup" : ""
        } ${
          showEmailVerificationCodeInputView
            ? "signup-login-modal--email-verification"
            : ""
        } ${isLoggedIn ? "signup-login-modal--logout-view" : ""} ${
          !showLoginView &&
          !showSignupView &&
          !showEmailVerificationCodeInputView &&
          !isLoggedIn
            ? "signup-login-modal--login"
            : ""
        }`}
        onClick={e => e.stopPropagation()}
      >
        <div className="signup-login-modal__close-wrapper">
          <button onClick={toggleSignupLoginModalVisible} className="btn">
            <img src="/assets/close.svg" alt="Close" height="20px" />
          </button>
        </div>
        {!showLoginView &&
          !showSignupView &&
          !showEmailVerificationCodeInputView &&
          !isLoggedIn && (
            <>
              <div className="signup-login-modal__content">
                <div className="signup-login-modal__content__title">
                  Kirjaudu, tai luo tunnus
                </div>
              </div>
              <form
                className="signup-login-modal__content__form"
                onSubmit={e => {
                  e.preventDefault();
                  const emailValidationError = validateEmail(email);
                  setEmailError(emailValidationError); // Validate email on submit
                  if (!emailValidationError) {
                    handleSubmitCheckIfUserExists();
                  }
                }}
              >
                <input
                  type="email"
                  placeholder="Sähköposti"
                  className="signup-login-modal__content__input"
                  value={email}
                  onChange={e => setEmail(e.target.value)} // Remove validation from here
                  required // Add this line
                  ref={initialEmailInputRef} // Add this line
                  autoComplete="email" // Add this line
                />
                {emailError && (
                  <div className="signup-login-modal__content__error">
                    {emailError}
                  </div>
                )}
                <button
                  type="submit"
                  className="signup-login-modal__content__button signup-login-modal__content__button--login"
                  disabled={isCheckingUser}
                >
                  {isCheckingUser ? (
                    <span className="loading-dots">
                      <span></span>
                    </span>
                  ) : (
                    "Seuraava"
                  )}
                </button>
                <div className="signup-login-modal__content__text">
                  Rekisteröitymällä luot Jaakko-tunnuksen ja sitoudut
                  noudattamaan palveluidemme{" "}
                  <a
                    href="/terms-conditions"
                    target="_blank"
                    rel="noreferrer"
                    className="signup-login-modal__content__text__link"
                  >
                    käyttöehtoja
                  </a>
                  , sekä ilmoitat lukeneesi ja hyväksyväsi Jaakkocom Oy:n{" "}
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                    className="signup-login-modal__content__text__link"
                  >
                    yleisen tietosuojakuvauksen
                  </a>
                  .
                </div>
              </form>
            </>
          )}
        {isLoggedIn && (
          <>
            <div className="signup-login-modal__content">
              <div className="signup-login-modal__content__title">
                Kirjaudu ulos
              </div>
            </div>
            <form
              className="signup-login-modal__content__form"
              onSubmit={e => {
                e.preventDefault();
                handleSubmitLogout();
              }}
            >
              <button
                className="signup-login-modal__content__button"
                disabled={isLoggingOut}
                type="submit"
              >
                {isLoggingOut ? (
                  <span className="loading-dots">
                    <span></span>
                  </span>
                ) : (
                  "Jatka"
                )}
              </button>
            </form>
          </>
        )}
        {showLoginView && (
          <>
            <div className="signup-login-modal__content">
              <div className="signup-login-modal__content__title">Kirjaudu</div>
            </div>
            <form
              className="signup-login-modal__content__form"
              onSubmit={e => {
                e.preventDefault();
                const passwordValidationError = validatePassword(password);
                setPasswordError(passwordValidationError); // Validate password on submit
                if (!passwordValidationError) {
                  handleSubmitLogin();
                }
              }}
            >
              <input
                type="password"
                placeholder="Salasana"
                className="signup-login-modal__content__input"
                value={password}
                onChange={e => {
                  setPassword(e.target.value);
                }}
                required // Add this line
                ref={loginPasswordInputRef} // Add this line
                autoComplete="currentPassword" // Add this line
              />
              {passwordError && (
                <div className="signup-login-modal__content__error">
                  {passwordError}
                </div>
              )}
              {loginError && (
                <div className="signup-login-modal__content__error">
                  {loginError}
                </div>
              )}
              <button
                className="signup-login-modal__content__button"
                disabled={isLoggingIn}
                type="submit"
              >
                {isLoggingIn ? (
                  <span className="loading-dots">
                    <span></span>
                  </span>
                ) : (
                  "Seuraava"
                )}
              </button>
            </form>
          </>
        )}
        {showSignupView && (
          <>
            <div className="signup-login-modal__content">
              <div className="signup-login-modal__content__title">
                Luo tunnus
              </div>
            </div>
            <form
              className="signup-login-modal__content__form"
              onSubmit={e => {
                e.preventDefault();
                const emailValidationError = validateEmail(email);
                setEmailError(emailValidationError); // Validate email on submit
                if (!emailValidationError) {
                  handleSubmitSignup();
                }
              }}
            >
              <input
                type="email"
                placeholder="Sähköposti"
                className="signup-login-modal__content__input"
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                }}
                required // Add this line
                ref={signupEmailInputRef} // Add this line
              />
              {emailError && (
                <div className="signup-login-modal__content__error">
                  {emailError}
                </div>
              )}
              <input
                type="password"
                placeholder="Salasana"
                className="signup-login-modal__content__input"
                value={password}
                onChange={e => {
                  setPassword(e.target.value);
                  setPasswordError(validatePassword(e.target.value)); // Add this line
                }}
                required // Add this line
                ref={signupPasswordInputRef} // Add this line
              />
              {passwordError && (
                <div className="signup-login-modal__content__error">
                  {passwordError}
                </div>
              )}
              <button
                className="signup-login-modal__content__button"
                disabled={
                  isSigningUp || passwordError !== "" || emailError !== ""
                }
                type="submit"
              >
                {isSigningUp ? (
                  <span className="loading-dots">
                    <span></span>
                  </span>
                ) : (
                  "Seuraava"
                )}
              </button>
            </form>
          </>
        )}
        {showEmailVerificationCodeInputView && (
          <>
            <div className="signup-login-modal__content">
              <div className="signup-login-modal__content__title">
                Vahvista sähköpostiosoite
              </div>
            </div>
            <div className="signup-login-modal__content__subtitle">
              <div>Anna vahvistuskoodi.</div>
              <div>
                Anna koodi, joka lähetettiin sähköpostiosoitteeseesi {email}{" "}
                (jos et näe sähköpostia, tarkista roskapostikansio)
              </div>
            </div>
            <form
              className="signup-login-modal__content__form"
              onSubmit={e => {
                e.preventDefault();
                handleSubmitEmailVerificationCode();
              }}
            >
              <input
                type="text"
                placeholder="Vahvistuskoodi"
                className="signup-login-modal__content__input"
                value={verificationCode}
                onChange={e => setVerificationCode(e.target.value)}
                required
                ref={verificationCodeRef}
              />
              {verificationError && ( // Add this block
                <div className="signup-login-modal__content__error">
                  {verificationError}
                </div>
              )}
              <button
                className="signup-login-modal__content__button"
                disabled={isVerifyingEmail}
                type="submit"
              >
                {isVerifyingEmail ? (
                  <span className="loading-dots">
                    <span></span>
                  </span>
                ) : (
                  "Seuraava"
                )}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default SignupLoginModal;
