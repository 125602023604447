import React from "react";

type HeartIconProps = {
  className: string;
  fillColor: string;
};

const HeartIcon = ({ className, fillColor }: HeartIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="37"
    viewBox="0 0 50 50"
    preserveAspectRatio="xMidYMid meet"
    className={className}
  >
    <g transform="translate(0,50) scale(0.1,-0.1)" fill="none" stroke="none">
      <path
        d="M93 415 c-38 -16 -62 -50 -70 -98 -12 -78 39 -158 150 -235 l77 -53 47 32 c93 63 132 101 158 152 28 58 31 93 11 142 -28 65 -111 92 -178 57 -37 -20 -39 -20 -75 -1 -44 22 -76 23 -120 4z"
        fill={fillColor}
        // stroke="#000000"
        // strokeWidth="15"
      />
    </g>
  </svg>
);

export default HeartIcon;
