import React from "react";

type Props = {
  hasRedemptionClause: "yes" | "no" | null;
};

const RedemptionClauseIcon = ({ hasRedemptionClause }: Props) => (
  <svg
    width="46.5"
    height="42"
    viewBox="0 0 62 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_277_1069)">
      <path
        d="M42.1891 6H7.81089C3.50291 6 0 9.50291 0 13.8109V48.1891C0 52.4971 3.50291 56 7.81089 56H42.1891C46.4971 56 50 52.4971 50 48.1891V13.8109C50 9.50291 46.4971 6 42.1891 6ZM48.7153 48.1891C48.7153 51.7862 45.7862 54.7153 42.1891 54.7153H7.81089C4.21377 54.7153 1.28469 51.7862 1.28469 48.1891V13.8109C1.28469 10.2138 4.21377 7.28469 7.81089 7.28469H42.1891C45.7862 7.28469 48.7153 10.2138 48.7153 13.8109V48.1891Z"
        fill="black"
        strokeWidth="1"
      />
      <path
        d="M39.0117 12.3977H13.061C11.9219 12.3977 11.0055 13.3227 11.0055 14.4532V42.5108H10.6715C10.6715 42.5108 10.6287 42.5194 10.6116 42.5194C8.48755 42.5879 6.7832 44.3265 6.7832 46.4676C6.7832 48.6088 8.35052 50.1846 10.3461 50.3816C10.406 50.3988 10.4745 50.4245 10.5431 50.4245H37.1618C37.1618 50.4245 37.1789 50.4245 37.196 50.4245C39.3115 50.3902 41.0244 48.6944 41.0758 46.579C41.0758 46.5618 41.0843 46.5447 41.0843 46.5276V14.4532C41.0843 13.3141 40.1594 12.3977 39.0288 12.3977H39.0117ZM8.05076 46.4676C8.05076 44.9945 9.2498 43.7955 10.7229 43.7955H34.1984C33.5561 44.4978 33.1535 45.4399 33.1535 46.4676C33.1535 47.4954 33.5646 48.4375 34.207 49.1398H10.7229C9.2498 49.1398 8.05076 47.9407 8.05076 46.4676ZM37.1189 49.1398C35.6458 49.1398 34.4468 47.9407 34.4468 46.4676C34.4468 44.9945 35.6458 43.7955 37.1189 43.7955C37.4701 43.7955 37.7613 43.5043 37.7613 43.1531C37.7613 42.802 37.4701 42.5108 37.1189 42.5108H12.2817V14.4532C12.2817 14.025 12.6328 13.6824 13.0525 13.6824H39.0117C39.4399 13.6824 39.7825 14.0336 39.7825 14.4532V46.4676C39.7825 47.9407 38.5835 49.1398 37.1104 49.1398H37.1189Z"
        fill="black"
        strokeWidth="1"
      />
      <path
        d="M34.3697 16.5944C32.7767 16.5944 31.4834 17.8876 31.4834 19.4806C31.4834 21.0736 32.7767 22.3669 34.3697 22.3669C35.9627 22.3669 37.2559 21.0736 37.2559 19.4806C37.2559 17.8876 35.9627 16.5944 34.3697 16.5944ZM34.3697 21.0822C33.4875 21.0822 32.7681 20.3628 32.7681 19.4806C32.7681 18.5985 33.4875 17.8791 34.3697 17.8791C35.2518 17.8791 35.9712 18.5985 35.9712 19.4806C35.9712 20.3628 35.2518 21.0822 34.3697 21.0822Z"
        fill="black"
        strokeWidth="1"
      />
      <path
        d="M15.1592 19.8061H27.7491C28.1003 19.8061 28.3915 19.5149 28.3915 19.1638C28.3915 18.8126 28.1003 18.5214 27.7491 18.5214H15.1592C14.808 18.5214 14.5168 18.8126 14.5168 19.1638C14.5168 19.5149 14.808 19.8061 15.1592 19.8061Z"
        fill="black"
        strokeWidth="1"
      />
      <path
        d="M15.1592 23.1035H27.7491C28.1003 23.1035 28.3915 22.8123 28.3915 22.4611C28.3915 22.11 28.1003 21.8188 27.7491 21.8188H15.1592C14.808 21.8188 14.5168 22.11 14.5168 22.4611C14.5168 22.8123 14.808 23.1035 15.1592 23.1035Z"
        fill="black"
        strokeWidth="1"
      />
      <path
        d="M15.1592 26.4094H33.1362C33.4874 26.4094 33.7786 26.1182 33.7786 25.767C33.7786 25.4159 33.4874 25.1247 33.1362 25.1247H15.1592C14.808 25.1247 14.5168 25.4159 14.5168 25.767C14.5168 26.1182 14.808 26.4094 15.1592 26.4094Z"
        fill="black"
        strokeWidth="1"
      />
      <path
        d="M33.1362 31.728H15.1592C14.808 31.728 14.5168 32.0192 14.5168 32.3703C14.5168 32.7215 14.808 33.0127 15.1592 33.0127H33.1362C33.4874 33.0127 33.7786 32.7215 33.7786 32.3703C33.7786 32.0192 33.4874 31.728 33.1362 31.728Z"
        fill="black"
        strokeWidth="1"
      />
      <path
        d="M35.0461 28.4221H15.1592C14.808 28.4221 14.5168 28.7133 14.5168 29.0644C14.5168 29.4155 14.808 29.7067 15.1592 29.7067H35.0461C35.3973 29.7067 35.6885 29.4155 35.6885 29.0644C35.6885 28.7133 35.3973 28.4221 35.0461 28.4221Z"
        fill="black"
        strokeWidth="1"
      />
    </g>
    {hasRedemptionClause === "yes" && (
      <g clipPath="url(#clip1_277_942)">
        <circle cx="51.5" cy="11.5" r="9.5" fill="white" />
        <path
          d="M51 0C44.9278 0 40 4.92778 40 11C40 17.0722 44.9278 22 51 22C57.0722 22 62 17.0722 62 11C62 4.92778 57.0588 0 51 0ZM57.9339 7.99755L50.448 18.0282L44.2681 12.2656C43.7295 11.754 43.6891 10.9058 44.2007 10.3672C44.7124 9.82864 45.5606 9.78825 46.0991 10.2999L50.0845 14.0159L55.7662 6.39535C56.2105 5.80294 57.0588 5.68176 57.6512 6.12607C58.2436 6.57038 58.3647 7.4186 57.9204 8.01101L57.9339 7.99755Z"
          fill="#14B685"
          strokeWidth="1"
        />
      </g>
    )}
    {hasRedemptionClause === "no" && (
      <g clipPath="url(#clip1_277_942)">
        <circle cx="51.5" cy="11.5" r="9.5" fill="white" />
        <g transform={`translate(39.2, 0) scale(1.345)`}>
          <path
            d="M9.04988 0C4.54988 0 0.879883 3.66 0.879883 8.17C0.879883 12.68 4.53988 16.34 9.04988 16.34C13.5599 16.34 17.2199 12.68 17.2199 8.17C17.2199 3.66 13.5599 0 9.04988 0ZM9.03988 12.81C8.48988 12.81 8.04988 12.37 8.04988 11.82C8.04988 11.27 8.48988 10.83 9.03988 10.83C9.58988 10.83 10.0299 11.27 10.0299 11.82C10.0299 12.37 9.58988 12.81 9.03988 12.81ZM10.0399 8.15C10.0399 8.7 9.58988 9.15 9.03988 9.15C8.48988 9.15 8.03988 8.7 8.03988 8.15V3.81C8.03988 3.26 8.48988 2.81 9.03988 2.81C9.58988 2.81 10.0399 3.26 10.0399 3.81V8.15Z"
            fill="#FF5E5E"
            strokeWidth="1"
          />
        </g>
      </g>
    )}
    <defs>
      <clipPath id="clip0_277_942">
        <rect width="50" height="50" fill="white" transform="translate(0 6)" />
      </clipPath>
      <clipPath id="clip1_277_942">
        <rect width="22" height="22" fill="white" transform="translate(40)" />
      </clipPath>
    </defs>
  </svg>
);

export default RedemptionClauseIcon;
