import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet"; // Import Helmet
import "./Report.scss";
import ApartmentCounts from "./ApartmentCounts";
import LoadingExistingReportSkeleton from "./LoadingExistingReportSkeleton";
import {
  ArticlesOfAssociationApartments,
  ArticlesOfAssociationBaseInfo,
  ArticlesOfAssociationCommercialSpaces,
  BuildingWithConvertedAddresses
} from "../HouseMap/types";
import { DocumentAndFileName } from "./FilesDropBox";
import { submitReportDocuments } from "./Report.submitReportDocuments";
import { fetchExistingReport } from "./Report.fetchExistingReport";
import BuildingIconAndName from "./BuildingIconAndName";
import PlotOwnershipAndRedemptionClause from "./PlotOwnershipAndRedemptionClause";
import DocumentDropzone from "./DocumentDropzone";
import HeartIcon from "./Heart";
import { getCookie } from "../AppContent";
import ApartmentIcon from "./ApartmentIcon";
import CommercialSpaceIcon from "./CommercialSpace";
import { CSSTransition } from "react-transition-group";
import ExpanderArrow from "./ExpanderArrow";
import "./Comments.scss";
import SendIcon from "./SendIcon";
import BackIcon from "./BackIcon";
import DOMPurify from "dompurify";

type Props = {
  selectedBuilding: BuildingWithConvertedAddresses;
  isLoggedIn: boolean;
  toggleReportVisible: () => void;
  setSelectedBuilding: (
    building: BuildingWithConvertedAddresses | null
  ) => void;
  setLoginModalVisible: (visible: boolean) => void;
};

type Comment = {
  id: number;
  content: string;
  timestamp: string;
  commenterOrderNumber: number;
};

// Add the helper function to calculate relative time
function getTimeAgo(timestamp: string): string {
  const now = new Date();
  const commentDate = new Date(timestamp);
  const diffMs = now.getTime() - commentDate.getTime();
  const diffMinutes = Math.floor(diffMs / (1000 * 60));

  if (diffMinutes < 1) {
    return "Nyt";
  }

  if (diffMinutes < 60) {
    return `${diffMinutes} minuuttia`;
  }

  const diffHours = Math.floor(diffMinutes / 60);
  if (diffHours < 24) {
    return `${diffHours} tuntia`;
  }

  const diffDays = Math.floor(diffHours / 24);
  return `${diffDays} päivää`;
}

const Report = (props: Props) => {
  const {
    selectedBuilding,
    isLoggedIn,
    toggleReportVisible,
    setSelectedBuilding,
    setLoginModalVisible
  } = props;
  const [isLoadingExistingReport, setIsLoadingExistingReport] = useState<
    boolean
  >(true);
  const [likeCount, setLikeCount] = useState<number>(0);
  const [userHasLiked, setUserHasLiked] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [analyzedFinancialStatement, setAnalyzedFinancialStatement] = useState<
    string | null
  >(null);
  const [
    analyzedMaintenanceNeedsAssessment,
    setAnalyzedMaintenanceNeedsAssessment
  ] = useState<string | null>(null);
  const [
    analyzedArticlesOfAssociationBaseInfo,
    setAnalyzedArticlesOfAssociationBaseInfo
  ] = useState<ArticlesOfAssociationBaseInfo | null>(null);
  const [
    analyzedArticlesOfAssociation,
    setAnalyzedArticlesOfAssociation
  ] = useState<string | null>(null);
  const [
    analyzedArticlesOfAssociationApartments,
    setAnalyzedArticlesOfAssociationApartments
  ] = useState<ArticlesOfAssociationApartments | null>(null);
  const [
    analyzedArticlesOfAssociationCommercialSpaces,
    setAnalyzedArticlesOfAssociationCommercialSpaces
  ] = useState<ArticlesOfAssociationCommercialSpaces | null>(null);
  const [
    apartmentsOwnedByTheHousingCompanyCount,
    setApartmentsOwnedByTheHousingCompanyCount
  ] = useState<number>(0);
  const [
    commercialSpacesOwnedByTheHousingCompanyCount,
    setCommercialSpacesOwnedByTheHousingCompanyCount
  ] = useState<number>(0);
  const [documentsAndFileNames, setDocumentsAndFileNames] = useState<
    DocumentAndFileName[]
  >([]);
  const [emptyReport, setEmptyReport] = useState<any>(null);
  const [isLoadingEmptyReport, setIsLoadingEmptyReport] = useState<boolean>(
    false
  );
  const [isAddingComment, setIsAddingComment] = useState<boolean>(false);
  const [comments, setComments] = useState<Comment[]>([]);
  const [
    notificationsSubscriptionsToggle,
    setNotificationsSubscriptionsToggle
  ] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string>("");
  const commentFormRef = useRef<HTMLDivElement | null>(null);

  const currentUrl = window.location.href;
  const addressObject =
    selectedBuilding.addresses && selectedBuilding.addresses.length
      ? selectedBuilding.addresses[0]
      : selectedBuilding.properties &&
        selectedBuilding.properties.addresses &&
        selectedBuilding.properties.addresses.length
      ? selectedBuilding.properties.addresses[0]
      : "";
  const addressString =
    typeof addressObject === "string"
      ? "taloon"
      : `taloon osoitteessa ${addressObject.street} ${addressObject.houseNumber}, ${addressObject.city}`;
  const titleAddressString =
    typeof addressObject === "string"
      ? ""
      : `${addressObject.street} ${addressObject.houseNumber}, ${addressObject.city} `;

  const articlesOfAssociationPlaceHolderText =
    "Taloyhtiössä on keskimääräistä enemmän pieniä yksiöitä, minkä seurauksena omistajina saattaa olla asuntosijoittajia.";
  const financialStatementPlaceHolderText =
    "Tulot ovat kasvaneet +21% viimeisen kolmen vuoden aikana taloyhtiön omistamien liikehuoneistojen vuokratuottojen positiivisen kehityksen myötä.";
  const maintenanceNeedsAssessmentPlaceHolderText =
    "Taloyhtiö suunnittelee putkiremonttia selvästi keskimääräistä käyttöikää myöhemmäksi, vaikka vesivahinkojakin on jo ollut.";
  const onlyArticlesOfAssociationText = "yhtiöjärjestys dokumenttia";
  const onlyFinancialStatementText = "taloyhtiön tilinpäätöstä";
  const onlyMaintenanceNeedsAssessmentText = "kunnossapitotarveselvitystä";
  const onlyArticlesOfAssociationAndFinancialStatementText =
    "yhtiöjärjestys dokumenttia ja taloyhtiön tilinpäätöstä";
  const onlyArticlesOfAssociationAndMaintenanceNeedsAssessmentText =
    "yhtiöjärjestys dokumenttia ja kunnossapitotarveselvitystä";
  const onlyFinancialStatementAndMaintenanceNeedsAssessmentText =
    "taloyhtiön tilinpäätöstä ja kunnossapitotarveselvitystä";
  const allDocumentsText =
    "yhtiöjärjestys dokumenttia, taloyhtiön tilinpäätöstä ja kunnossapitotarveselvitystä";
  const [
    isHousingCompanyInfoExpanded,
    setIsHousingCompanyInfoExpanded
  ] = useState<boolean>(false);
  const [isCommentsExpanded, setIsCommentsExpanded] = useState<boolean>(true);

  const toggleHousingCompanyInfoExpansion = () => {
    setIsHousingCompanyInfoExpanded(prevState => !prevState);
  };

  const toggleCommentsExpansion = () => {
    setIsCommentsExpanded(prevState => !prevState);
  };

  const handleSubmitReportDocuments = () => {
    submitReportDocuments({
      selectedBuilding,
      documentsAndFileNames,
      setIsUploading,
      setAnalyzedArticlesOfAssociationBaseInfo,
      setAnalyzedArticlesOfAssociationApartments,
      setAnalyzedArticlesOfAssociationCommercialSpaces,
      setAnalyzedArticlesOfAssociation,
      setAnalyzedFinancialStatement,
      setAnalyzedMaintenanceNeedsAssessment
    });
  };

  const likeBuilding = () => {
    fetch(`${process.env.REACT_APP_API_URL}/like-building`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": getCookie("csrf_access_token")
      },
      body: JSON.stringify({
        like: !userHasLiked,
        selected_building: selectedBuilding
      })
    })
      .then(res => res.json())
      .then((data: { like_count: number; user_has_liked: boolean }) => {
        setLikeCount(data.like_count);
        setUserHasLiked(data.user_has_liked);
      })
      .catch(error => {
        setIsUploading(false);
        console.error("Error:", error);
      });
  };

  const fetchBuilding = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/building?id=${selectedBuilding.id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "X-CSRF-TOKEN": getCookie("csrf_access_token")
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        // Handle the building data as needed
        if (data.properties.like_count) {
          console.log(
            "data.properties.like_count: ",
            data.properties.like_count
          );
          console.log(
            "data.properties.user_has_liked: ",
            data.properties.user_has_liked
          );
          setLikeCount(data.properties.like_count);
          setUserHasLiked(data.properties.user_has_liked);
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  const fetchComments = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/comments?building-id=${selectedBuilding.id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "X-CSRF-TOKEN": getCookie("csrf_access_token")
        }
      }
    )
      .then(res => res.json())
      .then(comments => {
        // Handle the building data as needed
        if (Array.isArray(comments)) {
          console.log("comments: ", comments);
          setComments(comments);
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchExistingReport({
      selectedBuilding,
      setAnalyzedArticlesOfAssociationBaseInfo,
      setAnalyzedArticlesOfAssociation,
      setAnalyzedArticlesOfAssociationApartments,
      setAnalyzedArticlesOfAssociationCommercialSpaces,
      setAnalyzedFinancialStatement,
      setAnalyzedMaintenanceNeedsAssessment,
      setLikeCount,
      setUserHasLiked,
      setIsLoadingExistingReport,
      setEmptyReport,
      setIsLoadingEmptyReport
    });
    fetchBuilding();
    fetchComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuilding]);

  useEffect(() => {
    setCommercialSpacesOwnedByTheHousingCompanyCount(
      analyzedArticlesOfAssociationCommercialSpaces?.commercialSpaces.filter(
        space => space.isControlledDirectlyByTheHousingCompany
      ).length || 0
    );
  }, [analyzedArticlesOfAssociationCommercialSpaces]);

  useEffect(() => {
    setApartmentsOwnedByTheHousingCompanyCount(
      analyzedArticlesOfAssociationApartments?.apartments.filter(
        apartment => apartment.isControlledByTheHousingCompany
      ).length || 0
    );
  }, [analyzedArticlesOfAssociationApartments]);

  const handleShareButtonClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Tutustu mielenkiintoiseen taloon Jaakko.com:issa",
          text: `Tutustu ${addressString}. Osallistu keskusteluun, käy tykkäämässä, tai syötä taloyhtiön dokumentteja.`,
          url: currentUrl
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else if (navigator.clipboard && navigator.clipboard.writeText) {
      // Use the modern Clipboard API
      navigator.clipboard
        .writeText(currentUrl)
        .then(() => {
          alert("Linkki taloon kopioitu leikepöydälle.");
        })
        .catch(err => {
          console.error("Could not copy text: ", err);
          alert("Linkin kopiointi epäonnistui. Kopioi linkki manuaalisesti.");
        });
    } else {
      // Fallback approach for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = currentUrl;

      // Avoid scrolling to bottom
      textArea.style.position = "fixed";
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.width = "2em";
      textArea.style.height = "2em";
      textArea.style.padding = "0";
      textArea.style.border = "none";
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";
      textArea.style.background = "transparent";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        if (successful) {
          alert("Linkki taloon kopioitu leikepöydälle.");
        } else {
          alert("Linkin kopiointi epäonnistui. Kopioi linkki manuaalisesti.");
        }
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
        alert("Linkin kopiointi epäonnistui. Kopioi linkki manuaalisesti.");
      }

      document.body.removeChild(textArea);
    }
  };

  const submitComment = (
    commentText: string,
    selectedBuilding: BuildingWithConvertedAddresses
  ) => {
    // Sanitize the comment text
    const sanitizedCommentText = DOMPurify.sanitize(commentText);

    fetch(`${process.env.REACT_APP_API_URL}/comment`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": getCookie("csrf_access_token")
      },
      body: JSON.stringify({
        comment: sanitizedCommentText,
        selected_building: selectedBuilding
      })
    })
      .then(res => res.json())
      .then(
        (data: {
          id: number;
          content: string;
          timestamp: string;
          commenterOrderNumber: number;
          is_subscribed: boolean;
        }) => {
          // Update comments state
          setComments(prevComments => [
            ...prevComments,
            {
              id: data.id,
              content: sanitizedCommentText,
              timestamp: data.timestamp,
              commenterOrderNumber: data.commenterOrderNumber
            }
          ]);
          setCommentText("");
          setIsAddingComment(false);
          setNotificationsSubscriptionsToggle(data.is_subscribed);
        }
      )
      .catch(error => {
        console.error("Error:", error);
      });
  };

  const handleSubmitComment = () => {
    submitComment(commentText, selectedBuilding);
  };

  const handleToggleSubscribeToNotifications = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/toggle-subscribe-to-notifications`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": getCookie("csrf_access_token")
        },
        body: JSON.stringify({
          selected_building: selectedBuilding
        })
      }
    )
      .then(res => res.json())
      .then(data => {
        setNotificationsSubscriptionsToggle(data.is_subscribed);
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
    if (isIOS) {
      document.body.classList.add("ios");
    } else {
      document.body.classList.remove("ios");
    }
  }, []);

  useLayoutEffect(() => {
    if (isAddingComment && commentFormRef.current) {
      commentFormRef.current.scrollIntoView();
    }
  }, [isAddingComment]);

  useEffect(() => {
    if (isLoggedIn) {
      fetch(
        `${process.env.REACT_APP_API_URL}/is-subscribed?building-id=${selectedBuilding.id}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "X-CSRF-TOKEN": getCookie("csrf_access_token")
          }
        }
      )
        .then(res => res.json())
        .then(data => {
          setNotificationsSubscriptionsToggle(data.is_subscribed);
        })
        .catch(error => {
          console.error("Error:", error);
        });
    }
  }, [isLoggedIn, selectedBuilding]);

  return (
    <div className="report-container">
      <Helmet>
        <title>Talosivu {titleAddressString}- Jaakko.com</title>
        <meta
          name="description"
          content="Tutustu kohteeseen ja jaa tietoa taloyhtiöstä."
        />
        <meta
          property="og:title"
          content="Tutustu mielenkiintoiseen taloon Jaakko.com:issa"
        />
        <meta
          property="og:description"
          content={`Tutustu ${addressString}. Osallistu keskusteluun, käy tykkäämässä, tai syötä taloyhtiön dokumentteja.`}
        />
        <meta
          property="og:image"
          content="https://www.jaakko.com/jaakko_logo.png"
        />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="report">
        <div className="report__close-wrapper">
          <button
            onClick={() => {
              toggleReportVisible();
              setSelectedBuilding(null);
            }}
            className="btn"
          >
            <img src="/assets/close.svg" alt="Close" height="20px" />
          </button>
        </div>
        {!isLoggedIn && (
          <div className="report__login-reminder">
            <div className="report__login-reminder__text">
              <span
                className="report__login-reminder__text__link"
                onClick={() => setLoginModalVisible(true)}
              >
                Kirjaudu tästä
              </span>{" "}
              sisään käyttääksesi toiminnallisuuksia.
            </div>
          </div>
        )}
        {isLoadingExistingReport ? (
          <LoadingExistingReportSkeleton />
        ) : (
          <>
            <div className="report__property-wrapper">
              <div className="report__controls">
                <div className="report__controls__notifications">
                  <label className="toggle-switch">
                    <input
                      disabled={!isLoggedIn}
                      type="checkbox"
                      checked={notificationsSubscriptionsToggle}
                      onChange={() => handleToggleSubscribeToNotifications()}
                    />
                    <span className="slider" />
                  </label>
                  <div className="report__controls__share-button__share-text">
                    Ilmoitukset
                  </div>
                </div>
                <button
                  className="report__controls__share-button"
                  onClick={handleShareButtonClick}
                >
                  <div className="report__controls__share-button__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0,0,256,256"
                      width="30px"
                      height="30px"
                      fillRule="nonzero"
                    >
                      <g
                        fill="#b7b7b7"
                        fillRule="nonzero"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="10"
                        strokeDasharray=""
                        strokeDashoffset="0"
                        fontFamily="none"
                        fontWeight="none"
                        fontSize="none"
                        textAnchor="none"
                        style={{ mixBlendMode: "normal" }}
                      >
                        <g transform="scale(8.53333,8.53333)">
                          <path d="M23,3c-2.20914,0 -4,1.79086 -4,4c0.00178,0.28117 0.03321,0.56136 0.09375,0.83594l-9.08203,4.54102c-0.75785,-0.87251 -1.85604,-1.3746 -3.01172,-1.37695c-2.20914,0 -4,1.79086 -4,4c0,2.20914 1.79086,4 4,4c1.15606,-0.0013 2.25501,-0.5027 3.01367,-1.375l9.07617,4.53906c-0.05923,0.27472 -0.08934,0.55491 -0.08984,0.83594c0,2.20914 1.79086,4 4,4c2.20914,0 4,-1.79086 4,-4c0,-2.20914 -1.79086,-4 -4,-4c-1.15606,0.0013 -2.25501,0.5027 -3.01367,1.375l-9.07617,-4.53906c0.05923,-0.27472 0.08934,-0.55491 0.08984,-0.83594c-0.00192,-0.28051 -0.03334,-0.56005 -0.09375,-0.83398l9.08203,-4.54102c0.75821,0.87178 1.85635,1.37313 3.01172,1.375c2.20914,0 4,-1.79086 4,-4c0,-2.20914 -1.79086,-4 -4,-4z"></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="report__controls__share-button__share-text">
                    Jaa
                  </div>
                </button>
                <button
                  disabled={!isLoggedIn || isLoadingEmptyReport}
                  className="report__controls__like-button"
                  onClick={() => {
                    if (isLoggedIn) {
                      likeBuilding();
                    }
                  }}
                >
                  <div className="report__controls__like-button__icon-and-count">
                    <HeartIcon
                      className="report__controls__like-button__icon-and-count__icon"
                      fillColor={userHasLiked ? "#FF5E5E" : "#FF8E8E"}
                    />
                    <div
                      className={`report__controls__like-button__icon-and-count__like-count`}
                    >
                      {likeCount || 0}
                    </div>
                  </div>
                  <div className="report__controls__like-button__like-text">
                    Tykkää
                  </div>
                </button>
              </div>
              <div className="report__property">
                <BuildingIconAndName
                  analyzedArticlesOfAssociationBaseInfo={
                    analyzedArticlesOfAssociationBaseInfo
                  }
                  selectedBuilding={selectedBuilding}
                />
                <PlotOwnershipAndRedemptionClause
                  analyzedArticlesOfAssociationBaseInfo={
                    analyzedArticlesOfAssociationBaseInfo
                  }
                />
              </div>
            </div>
            <div className="report__horizontal-line" />
            <div className="report__jaakko-wrapper">
              <div className="report__jaakko-head">
                <img src="/assets/jaakko_head.png" alt="Jaakko" height="64px" />
              </div>
              <div className="report__jaakko-text-wrapper">
                <div className="report__content__title">Jaakko</div>
                {/*                 <div className="report__jaakko-talking">
                  {isLoadingEmptyReport || isLoadingExistingReport ? (
                    <span className="jaakko-talking-loading-dots">
                      <span></span>
                    </span>
                  ) : emptyReport
                    ? emptyReport.analyysi
                    : `Alla analyysi taloyhtiön rakenteesta, taloudellisesta
                  tilanteesta ja remonteista. Analyysin lähteenä on käytetty
                  ${analyzedArticlesOfAssociation &&
                      analyzedFinancialStatement &&
                      analyzedMaintenanceNeedsAssessment
                      ? allDocumentsText
                      : analyzedArticlesOfAssociation &&
                        analyzedFinancialStatement
                        ? onlyArticlesOfAssociationAndFinancialStatementText
                        : analyzedArticlesOfAssociation &&
                          analyzedMaintenanceNeedsAssessment
                          ? onlyArticlesOfAssociationAndMaintenanceNeedsAssessmentText
                          : analyzedFinancialStatement &&
                            analyzedMaintenanceNeedsAssessment
                            ? onlyFinancialStatementAndMaintenanceNeedsAssessmentText
                            : analyzedArticlesOfAssociation
                              ? onlyArticlesOfAssociationText
                              : analyzedFinancialStatement
                                ? onlyFinancialStatementText
                                : analyzedMaintenanceNeedsAssessment
                                  ? onlyMaintenanceNeedsAssessmentText
                                  : allDocumentsText
                    }.`}
                </div> */}
                <div className="report__jaakko-talking">
                  {isLoadingEmptyReport || isLoadingExistingReport ? (
                    <span className="jaakko-talking-loading-dots">
                      <span></span>
                    </span> /* : analyzedArticlesOfAssociation ||
                    analyzedFinancialStatement ||
                    analyzedMaintenanceNeedsAssessment ? (
                    <>
                      <div className="report__jaakko-talking__text__first">
                        Alla taloyhtiön dokumentteihin perustuvia havaintojani
                        taloyhtiöstä. Mikäli tiedot ovat vajaat, voit ladata
                        lisää dokumentteja yllä.
                      </div>
                      <div className="report__jaakko-talking__text">
                        Olen beta-vaiheessa, joten tietoni ovat vielä rajalliset
                        ja saatan tehdä virheitä. Tarkistathan siis tärkeät
                        asiat aina vielä erikseen.
                      </div>
                    </>
                  ) */
                  ) : emptyReport && emptyReport.analyysi ? (
                    <>
                      <div className="report__jaakko-talking__text__first">
                        {`${emptyReport.analyysi}`}
                        <br />
                        <br />
                        Olen beta-versio, lähetäthän palautetta:{" "}
                        <a
                          className="report__jaakko-talking__link"
                          href="mailto:support@jaakko.com"
                        >
                          support@jaakko.com
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="report__jaakko-talking__text__first">
                        Alla taloyhtiön dokumentteihin perustuvia havaintojani
                        taloyhtiöstä. Mikäli tiedot ovat vajaat, voit ladata
                        lisää dokumentteja yllä.
                      </div>
                      <div className="report__jaakko-talking__text">
                        Olen beta-vaiheessa, joten tietoni ovat vielä rajalliset
                        ja saatan tehdä virheitä. Tarkistathan siis tärkeät
                        asiat aina vielä erikseen.
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="report__content__section__expander">
              <button
                onClick={toggleHousingCompanyInfoExpansion}
                className="report__content__section__expander__button"
              >
                Taloyhtiö
                <ExpanderArrow isExpanded={isHousingCompanyInfoExpanded} />
              </button>
            </div>
            <CSSTransition
              in={isHousingCompanyInfoExpanded}
              timeout={300}
              classNames="dropzone"
              unmountOnExit
            >
              <>
                {isLoggedIn && (
                  <>
                    <DocumentDropzone
                      documentsAndFileNames={documentsAndFileNames}
                      setDocumentsAndFileNames={setDocumentsAndFileNames}
                      handleSubmitReportDocuments={handleSubmitReportDocuments}
                      isUploading={isUploading}
                    />
                  </>
                )}
                <div className="report__content">
                  <div className="report__content__sub-section">
                    <div className="report__content__sub-title">Taloyhtiö</div>
                    <div
                      className={`report__info-box${
                        analyzedArticlesOfAssociation
                          ? ""
                          : " report__info-box--blurred"
                      }`}
                    >
                      <div className="report__info-box__rectangle report__info-box__rectangle--yellow" />
                      <div className="report__info-box__text">
                        {analyzedArticlesOfAssociation ||
                          articlesOfAssociationPlaceHolderText}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="report__horizontal-line" />
                <div className="report__owned-apartments-and-commercial-spaces-wrapper">
                  <div className="report__quantitative_content">
                    <ApartmentCounts
                      analyzedArticlesOfAssociationApartments={
                        analyzedArticlesOfAssociationApartments
                      }
                    />
                    <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company">
                      <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company__title">
                        Taloyhtiön omistukset
                      </div>
                      <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company__apartments-and-commercial-spaces">
                        <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company__apartments">
                          <ApartmentIcon />
                          <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company__apartments__count">
                            <span
                              className={`${
                                analyzedArticlesOfAssociationApartments
                                  ? ""
                                  : "report__apartments-and-commercial-spaces-owned-by-the-housing-company__apartments__count--blurred"
                              }`}
                            >
                              {apartmentsOwnedByTheHousingCompanyCount}
                            </span>
                            <span>kpl</span>
                          </div>
                          <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company__apartments__text">
                            Huoneistoja
                          </div>
                        </div>
                        <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company__commercial-spaces">
                          <CommercialSpaceIcon />
                          <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company__commercial-spaces__count">
                            <span
                              className={`${
                                analyzedArticlesOfAssociationApartments
                                  ? ""
                                  : "report__apartments-and-commercial-spaces-owned-by-the-housing-company__commercial-spaces__count--blurred"
                              }`}
                            >
                              {commercialSpacesOwnedByTheHousingCompanyCount}
                            </span>
                            <span>kpl</span>
                          </div>
                          <div className="report__apartments-and-commercial-spaces-owned-by-the-housing-company__commercial-spaces__text">
                            Liiketiloja
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="report__horizontal-line" />
                <div className="report__content">
                  <div className="report__content__sub-section">
                    <div className="report__content__sub-title">Talous</div>
                    <div
                      className={`report__info-box${
                        analyzedFinancialStatement
                          ? ""
                          : " report__info-box--blurred"
                      }`}
                    >
                      <div className="report__info-box__rectangle report__info-box__rectangle--green" />
                      <div className="report__info-box__text">
                        {analyzedFinancialStatement ||
                          financialStatementPlaceHolderText}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="report__content">
                  <div className="report__content__sub-section">
                    <div className="report__content__sub-title">Remontit</div>
                    <div
                      className={`report__info-box${
                        analyzedMaintenanceNeedsAssessment
                          ? ""
                          : " report__info-box--blurred"
                      }`}
                    >
                      <div className="report__info-box__rectangle report__info-box__rectangle--red" />
                      <div className="report__info-box__text">
                        {analyzedMaintenanceNeedsAssessment ||
                          maintenanceNeedsAssessmentPlaceHolderText}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </CSSTransition>
            <div className="report__horizontal-line" />
            <div className="report__content__section__expander">
              <button
                onClick={toggleCommentsExpansion}
                className="report__content__section__expander__button"
              >
                Keskustelu
                <ExpanderArrow isExpanded={isCommentsExpanded} />
              </button>
            </div>
            <CSSTransition
              in={isCommentsExpanded}
              timeout={300}
              classNames="dropzone"
              unmountOnExit
            >
              <>
                <div className="comments">
                  {comments.length > 0 && !isAddingComment && (
                    <div className="comments__comments-list">
                      {comments.map(comment => {
                        const commenterOrderNumber =
                          comment.commenterOrderNumber;

                        return (
                          <div
                            className={`comments__comment comments__comment--${
                              commenterOrderNumber <= 10
                                ? commenterOrderNumber
                                : "11"
                            }`}
                            key={comment.id}
                          >
                            <div className="comments__comment__commenter-order-number-and-timestamp-wrapper">
                              <div className="comments__comment__commenter-order-number">
                                {comment.commenterOrderNumber === 1
                                  ? "Eka."
                                  : `${comment.commenterOrderNumber}.`}
                              </div>
                              <div className="comments__comment__timestamp">
                                {getTimeAgo(comment.timestamp)}
                              </div>
                            </div>
                            <div className="comments__comment__content">
                              {comment.content}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {!comments.length && !isAddingComment && (
                    <div className="comments__empty-state">
                      <div className="comments__empty-state__text">
                        <span>Haluatko kertoa tai kysyä talosta jotain?</span>
                        <span>Ole ensimmäinen anonyymi keskustelija! 🥳</span>
                      </div>
                    </div>
                  )}
                  {isAddingComment ? (
                    <>
                      <div
                        className="comments__add-comment-form"
                        ref={commentFormRef}
                      >
                        <textarea
                          className="comments__add-comment-form__textarea"
                          maxLength={250}
                          placeholder="Jaa kokemuksesi talosta tai kysy jotain..."
                          onChange={e => {
                            setCommentText(e.target.value);
                          }}
                        />
                      </div>
                      <div className="comments__send-comment-button-wrapper">
                        <button
                          className="comments__add-comment-form__back-button"
                          onClick={() => {
                            setIsAddingComment(false);
                          }}
                        >
                          <span>Takaisin</span>
                          <BackIcon />
                        </button>
                        <button
                          className="comments__add-comment-form__send-button"
                          disabled={!commentText}
                          onClick={handleSubmitComment}
                        >
                          <span>Lähetä</span> <SendIcon />
                        </button>
                      </div>
                    </>
                  ) : (
                    <div
                      className={`comments__add-comment-button-wrapper${
                        comments.length === 0
                          ? " comments__add-comment-button-wrapper--no-comments"
                          : ""
                      }`}
                    >
                      <button
                        className="comments__add-comment-button-wrapper__button"
                        disabled={!isLoggedIn}
                        onClick={() => {
                          setIsAddingComment(true);
                        }}
                      >
                        <div className="comments__add-comment-button-wrapper__button__plus-sign">
                          +
                        </div>
                      </button>
                    </div>
                  )}
                </div>
              </>
            </CSSTransition>
          </>
        )}
      </div>
    </div>
  );
};

export default Report;
