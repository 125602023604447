import data from "../../data/4326_all_polygon_hki00_100-120-130-140-150_with_centers";

const buildings = data.features
  .filter(building => building.properties.address_suomi)
  .map(building => ({ ...building, like: false, apartments: [] }));

let likedBuildings = JSON.parse(localStorage.getItem("likedBuildings")) || [];
buildings.forEach(building => {
  likedBuildings.forEach(localBuild => {
    if (
      localBuild.properties.address_suomi === building.properties.address_suomi
    ) {
      building.like = true;
    }
  });
});

const mapCenter = { lat: 60.161089, lng: 24.946167 };

const initialState = {
  buildings: {
    buildings: buildings,
    pending: false,
    error: null,
    mapCenter: mapCenter,
    selectedBuilding: null,
    selectedBuildList: [],
    selectedBuildingIndex: -1,
    selectedApartment: null
  }
};

export default initialState;
