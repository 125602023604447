export function getCenterMapBuildings(buildings) {
  if (!buildings || buildings.length === 0) {
    console.error("getCenterMapBuildings: No buildings provided.");
    return null;
  }

  let totalLat = 0;
  let totalLng = 0;
  let count = 0;

  buildings.forEach(building => {
    const coords = building.geometry.coordinates;
    if (coords && coords.length > 0) {
      // Assuming buildings are polygons and we can get the first coordinate
      const [lng, lat] = coords[0][0];
      if (typeof lat === "number" && typeof lng === "number") {
        totalLat += lat;
        totalLng += lng;
        count++;
      }
    }
  });

  if (count === 0) {
    console.error("getCenterMapBuildings: No valid coordinates found.");
    return null;
  }

  return {
    lat: totalLat / count,
    lng: totalLng / count
  };
}

export const distanceToUserLocation = (buildings, coord) =>
  buildings.map(building => {
    const lat = building.properties.lat_center_y;
    const lng = building.properties.lng_center_x;
    let centerCoord = { lat, lng };
    building.properties.distanceToUser = eucDistance(centerCoord, coord);
    return building;
  });

// Euclidean Distance
export const eucDistance = (p1, p2) => {
  let distance = Math.sqrt((p2.lng - p1.lng) ** 2 + (p2.lat - p1.lat) ** 2);
  return distance;
};

const findClosestBuilding = buildingsWithDistanceToUser =>
  buildingsWithDistanceToUser.reduce((minBuilding, building) => {
    return building.properties.distanceToUser <
      minBuilding.properties.distanceToUser
      ? building
      : minBuilding;
  });

export const getMapCenter = (buildings, position) => {
  const coord = {
    lat: position.coords.latitude,
    lng: position.coords.longitude
  };

  // map: calculate distance to each from user location - distanceToUserLocation
  const buildingsWithDistanceToUser = distanceToUserLocation(buildings, coord);

  // reduce: find min distance
  const closestBuilding = findClosestBuilding(buildingsWithDistanceToUser);

  // center to the building with min distance to user location
  return closestBuilding.properties.distanceToUser <= 0.001
    ? coord
    : { lat: 60.161089, lng: 24.946167 };
};

export const buildSizeText = apartment => {
  let size = apartment["Size (m2)"];
  let additional_size = apartment["Additional size (m2)"];
  let sizeText = apartment["Size (m2)"] + "m2";
  if (!size.length) sizeText = "";
  if (size.length && additional_size) sizeText += "+" + additional_size + "m2";

  let brackettext = "(";
  let bracketEndText = ")";
  let bracketRoomSeparator = "";
  let sizeBracketSeparate = "";

  if (!size.length && !additional_size.length) {
    brackettext = "";
    bracketEndText = "";
  }

  if (apartment.Rooms.length) {
    brackettext += apartment.Rooms + "h";
    bracketRoomSeparator = "+";
  }

  if (apartment.Kitchen === "k") {
    brackettext += bracketRoomSeparator + "k";
    bracketRoomSeparator = "+";
  }

  if (apartment.Bathroom.length > 0) {
    brackettext += bracketRoomSeparator + apartment.Bathroom;
    bracketRoomSeparator = "+";
  }

  if (apartment.Sauna.length > 0) {
    brackettext += bracketRoomSeparator + apartment.Sauna;
    bracketRoomSeparator = "+";
  }
  if (apartment.Other.length > 0 && brackettext.length + sizeText.length < 11) {
    brackettext += bracketRoomSeparator + apartment.Other;
    bracketRoomSeparator = "+";
  } else if (apartment.Other.length > 0) {
    brackettext += bracketRoomSeparator + apartment.Other[0];
    bracketRoomSeparator = "+";
  }

  brackettext += bracketEndText;

  if (sizeText.length > 0) sizeBracketSeparate = " ";
  if (brackettext.length < 2) brackettext = "";

  sizeText += sizeBracketSeparate + brackettext;

  if (sizeText.length < 2) sizeText = apartment.Other;

  sizeText = sizeText.replace("()", "");

  return sizeText;
};

export var curDay = function(sp) {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //As January is 0.
  var yyyy = today.getFullYear();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  return mm + sp + dd + sp + yyyy;
};
