import React, { useEffect, useRef, useState } from "react";
import mapboxgl, { MapMouseEvent } from "mapbox-gl";

import "mapbox-gl/dist/mapbox-gl.css";
import { BuildingWithConvertedAddresses, LatLng, POI } from "./types";
import { getCookie } from "../AppContent";
import "./HouseMap.scss";

interface MapBoxExampleProps {
  mapCenter: LatLng;
  selectedBuilding: BuildingWithConvertedAddresses | null;
  setMapCenter: (center: LatLng) => void;
  selectBuilding: (building: BuildingWithConvertedAddresses) => void;
}

const BuildingMap = ({
  mapCenter,
  setMapCenter,
  selectedBuilding,
  selectBuilding
}: MapBoxExampleProps) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const speechBubbleRef = useRef<HTMLDivElement>(null);
  const jaakkoInfoRef = useRef<HTMLDivElement>(null);

  const prevSelectedBuildingRef = useRef<BuildingWithConvertedAddresses | null>(
    null
  );

  const [showZoomHint, setShowZoomHint] = useState(true);
  const [showClickBuildingHint, setShowClickBuildingHint] = useState(true);
  const [showJaakkoSpeechBubble, setShowJaakkoSpeechBubble] = useState(false);
  const [showClickJaakkoHint, setShowClickJaakkoHint] = useState(true);
  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setVh();

    window.addEventListener("resize", setVh);

    return () => {
      window.removeEventListener("resize", setVh);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        speechBubbleRef.current &&
        !speechBubbleRef.current.contains(event.target as Node) &&
        jaakkoInfoRef.current &&
        !jaakkoInfoRef.current.contains(event.target as Node)
      ) {
        setShowJaakkoSpeechBubble(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [speechBubbleRef, jaakkoInfoRef]);

  // Initialize the map only once
  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!;

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current!,
      style: "mapbox://styles/jaakkocom/cm2spvy4x00cf01qu6g8vdyll",
      center: [24.949, 60.1584], // Updated Helsinki coordinates
      zoom: 14
    });

    /*    const zoomHandler = () => {
         const zoom = mapRef.current!.getZoom();
         console.log("zoom", zoom);
   
         setShowHint(zoom < 17);
       }; */

    mapRef.current.on("load", () => {
      // Handle user location
      navigator.geolocation.getCurrentPosition(
        position => {
          const userCoordinates: LatLng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          /*  mapRef.current!.flyTo({
             center: [userCoordinates.lng, userCoordinates.lat]
           }); */
          // setMapCenter(userCoordinates);
        },
        error => {
          console.error("Error getting user location:", error);
          // Fallback to Helsinki is already set in initial state
        }
      );

      // Change cursor to pointer when hovering over a building
      mapRef.current!.on("mouseenter", "building", () => {
        mapRef.current!.getCanvas().style.cursor = "pointer";
      });

      mapRef.current!.on("mouseleave", "building", () => {
        mapRef.current!.getCanvas().style.cursor = "";
      });

      mapRef.current!.on("click", (e: MapMouseEvent) =>
        handleOnClickBuilding(e, mapRef, selectBuilding)
      );

      // mapRef.current!.on("zoom", zoomHandler);
    });

    // Call zoomHandler initially to set the correct state based on initial zoom level
    // zoomHandler();

    return () => {
      if (mapRef.current) {
        // mapRef.current.off("zoom", zoomHandler);
        mapRef.current.remove();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs only once

  // Update the map center smoothly when mapCenter changes
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [mapCenter.lng, mapCenter.lat],
        essential: true // Animation is considered essential with respect to prefers-reduced-motion
        // Zoom parameter omitted to retain current zoom level
      });
    }
  }, [mapCenter]);

  useEffect(() => {
    if (prevSelectedBuildingRef.current && !selectedBuilding) {
      fetchBuildingsWithinBounds(mapRef);
    }
    prevSelectedBuildingRef.current = selectedBuilding;
  }, [selectedBuilding]);

  useEffect(() => {
    fetchBuildingsWithinBounds(mapRef);

    // Add event listener for 'moveend' to fetch buildings when map view changes
    mapRef.current!.on("moveend", () => {
      fetchBuildingsWithinBounds(mapRef);
    });
  }, []);

  return (
    <div className="map-wrapper">
      <>
        <div className="map-hints">
          {showClickBuildingHint && (
            <div className="click-building-hint">
              Klikkaa rakennusta, tai hae osoitteella
              <img
                src="/assets/close.svg"
                alt="Close"
                height="22px"
                onClick={() => setShowClickBuildingHint(false)}
              />
            </div>
          )}
          {showZoomHint && (
            <div className="zoom-in-hint">
              Zoomaa nähdäksesi talonumerot
              <img
                src="/assets/close.svg"
                alt="Close"
                height="22px"
                onClick={() => setShowZoomHint(false)}
              />
            </div>
          )}
          {showClickJaakkoHint && (
            <div className="click-jaakko-hint">
              Klikkaa Jaakkoa
              <img
                src="/assets/close.svg"
                alt="Close"
                height="22px"
                onClick={() => setShowClickJaakkoHint(false)}
              />
            </div>
          )}
          {showJaakkoSpeechBubble && (
            <div className="jaakko-speech-bubble" ref={speechBubbleRef}>
              Osallistu keskusteluun - jaa kokemuksia, kysy kysymyksiä ja opi
              lisää taloyhtiöistä. Tykkää kiinnostavista kohteista, kuten
              entisistä kodeistasi tai taloista, joissa haaveilet asuvasi, ja
              inspiroi muita jakamaan omia kokemuksiaan.
              <br />
              <br />
              Keskustelu on anonyymiä ja helppoa. Kysy rohkeasti, jaa omia
              havaintojasi tai seuraa, mitä muut kertovat. Jokainen kommentti
              tekee taloyhtiöistä läpinäkyvämpiä kaikille!{" "}
              <a href="/faq">Lue lisää...</a>
            </div>
          )}
          <div
            className="jaakko-info"
            onClick={() => setShowJaakkoSpeechBubble(!showJaakkoSpeechBubble)}
            ref={jaakkoInfoRef}
          >
            <img
              src="/assets/info_jaakko.png"
              alt="Jaakko info"
              height="60px"
            />
          </div>
        </div>
      </>
      <div
        ref={mapContainerRef}
        id="map-container"
        className="map-container"
      ></div>
    </div>
  );
};

const handleOnClickBuilding = (
  e: MapMouseEvent,
  mapRef: React.MutableRefObject<mapboxgl.Map | null>,
  selectBuilding: (building: BuildingWithConvertedAddresses) => void
) => {
  const buildingFeatures = mapRef.current!.queryRenderedFeatures(e.point, {
    layers: ["building"] // Ensure this matches your building layer name
  });

  if (buildingFeatures.length > 0) {
    const buildingFeature = buildingFeatures[0];

    const building: BuildingWithConvertedAddresses = {
      id: buildingFeature.id.toString(),
      centroid: { lat: e.lngLat.lat, lng: e.lngLat.lng },
      addresses: [],
      geometry: { type: "Polygon", coordinates: [] },
      properties: { name: "", addresses: [] },
      pois: []
    };

    // Get building outline coordinates
    if (buildingFeature.geometry.type === "Polygon") {
      building.geometry.coordinates = buildingFeature.geometry.coordinates;
    }

    // Now perform reverse geocoding
    const lngLat = e.lngLat;
    const accessToken = mapboxgl.accessToken;

    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${lngLat.lng},${lngLat.lat}.json?access_token=${accessToken}&types=address`
    )
      .then(response => response.json())
      .then(data => {
        if (data.features && data.features.length > 0) {
          const place = data.features[0];
          building.properties.name = place.place_name;
          const getContext = (type: string) => {
            const context = place.context?.find((c: any) =>
              c.id.startsWith(type)
            );
            return context ? context.text : "Unknown";
          };

          building.properties.addresses.push({
            country: getContext("country"),
            region: getContext("region"),
            city: getContext("place"),
            postcode: getContext("postcode"),
            street: place.text || "Unknown",
            houseNumber: place.address || "Unknown",
            coordinates: { lat: lngLat.lat, lng: lngLat.lng }
          });
          building.addresses.push({
            country: getContext("country"),
            region: getContext("region"),
            city: getContext("place"),
            postcode: getContext("postcode"),
            street: place.text || "Unknown",
            houseNumber: place.address || "Unknown",
            coordinates: { lat: lngLat.lat, lng: lngLat.lng }
          });

          // Now fetch POIs using the Tilequery API
          const tilesetId = "mapbox.mapbox-streets-v8";
          const radius = 500; // in meters
          const layers =
            "poi_label,airport_label,transit_stop_label,ferry_aerialway_label,golf_hole_label,path_pedestrian_label,admin_0_boundary_disputed,admin_0_boundary,admin_1_boundary,admin_0_boundary_bg,aerialway,bridge_rail_tracks,bridge_rail,bridge_pedestrian,bridge_steps,bridge_path,bridge_pedestrian_case,bridge_steps_bg,bridge_path_bg,road_rail_tracks,road_rail,ferry_auto,ferry,golf_hole_line,road_pedestrian,road_steps,road_path,road_pedestrian_case,road_steps,road_path_bg"; // Layer containing POIs

          fetch(
            `https://api.mapbox.com/v4/${tilesetId}/tilequery/${lngLat.lng},${lngLat.lat}.json?radius=${radius}&layers=${layers}&access_token=${accessToken}`
          )
            .then(response => response.json())
            .then(tilequeryData => {
              const pois: POI[] = tilequeryData.features.map((feature: any) => {
                const properties = feature.properties;
                return {
                  id: feature.id.toString(),
                  type: feature.type,
                  properties: {
                    name: properties.name || properties.ref || "Unknown",
                    category: properties.class || properties.type || "Unknown",
                    layer: properties.tilequery.layer,
                    distanceInMeters: properties.tilequery.distance
                  },
                  geometry: feature.geometry
                };
              });

              // Add POIs to the building object
              building.pois = pois;

              // Now select the building with POIs
              selectBuilding(building);
            })
            .catch(error => {
              console.error("Error fetching POIs:", error);
              // Select the building even if POI fetching fails
              selectBuilding(building);
            });
        } else {
          console.log("No address found for this location");
        }
      })
      .catch(error => {
        console.error("Error with reverse geocoding request:", error);
      });
  } else {
    console.log("No building found at this location");
  }
};

const fetchBuildingsWithinBounds = (
  mapRef: React.MutableRefObject<mapboxgl.Map | null>
) => {
  const bounds = mapRef.current!.getBounds();
  const bbox = {
    sw: [bounds.getWest(), bounds.getSouth()], // Southwest corner
    ne: [bounds.getEast(), bounds.getNorth()] // Northeast corner
  };

  fetch(`${process.env.REACT_APP_API_URL}/buildings-in-bounds`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": getCookie("csrf_access_token")
    },
    credentials: "include",
    body: JSON.stringify(bbox)
  })
    .then(response => response.json())
    .then(data => {
      // Update your map layer with the returned GeoJSON data
      if (mapRef.current!.getSource("clicked-buildings")) {
        // Update existing source data
        (mapRef.current!.getSource(
          "clicked-buildings"
        ) as mapboxgl.GeoJSONSource).setData(data);
      } else {
        // TÄÄLLÄ VOISI YRITTTÄÄ SELVITTÄÄ SEN PUUTTUVAT SIIVUT RAKENNUKSISTA BUGIN
        // Add new source and layer
        mapRef.current!.addSource("clicked-buildings", {
          type: "geojson",
          data: data
        });
        mapRef.current!.addLayer({
          id: "clicked-buildings-layer",
          type: "fill",
          source: "clicked-buildings",
          filter: ["==", ["get", "has_report"], true],
          paint: {
            // "fill-color": "#3E55CD",
            // "fill-color": "#051FA2",
            // "fill-color": "#FF5E5E",
            // "fill-color": "#F7CF3E",
            // "fill-color": "#F8C100",
            //  "fill-color": "#14B685",
            // "fill-color": "#8868E5",
            // "fill-color": "#6340C7",
            "fill-color": "#8095FF",
            // "fill-color": "#018860",
            "fill-opacity": 0.7
          }
        });
        // Add a line layer for the outline
        mapRef.current!.addLayer({
          id: "clicked-buildings-outline",
          type: "line",
          source: "clicked-buildings",
          filter: ["==", ["get", "has_report"], true],
          paint: {
            "line-color": "#F9F9F9", // White color for the outline
            "line-width": 0.5, // Adjust the width as needed
            "line-opacity": 0.6
          }
        });

        // After the 'clicked-buildings-outline' layer, add the heart icons and like counts
        mapRef.current!.loadImage(
          `${process.env.PUBLIC_URL}/heart.png`,
          (error, image) => {
            if (error) {
              console.error("Error loading heart icon:", error);
              return;
            }
            // Add the image to the map's style
            if (!mapRef.current!.hasImage("heart-icon")) {
              mapRef.current!.addImage("heart-icon", image!);
            }

            // Add a symbol layer to display the heart icon and like count on buildings with like_count > 0
            mapRef.current!.addLayer({
              id: "building-like-icons",
              type: "symbol",
              source: "clicked-buildings",
              filter: [">", ["get", "like_count"], 0],
              minzoom: 13,
              layout: {
                "icon-image": "heart-icon",
                "icon-size": 0.6,
                "icon-offset": [-15, 0],
                "icon-allow-overlap": true,
                "text-field": ["get", "like_count"],
                "text-font": ["Open Sans Regular", "Arial Unicode MS Regular"],
                "text-size": 12,
                "text-offset": [-0.75, -0.5],
                "text-anchor": "top",
                "text-allow-overlap": true
              },
              paint: {
                "text-color": "#FFF"
              }
            });
          }
        );

        // After the 'clicked-buildings-outline' layer, add the comment icons and comment counts
        mapRef.current!.loadImage(
          `${process.env.PUBLIC_URL}/chata.png`,
          (error, image) => {
            if (error) {
              console.error("Error loading comment icon:", error);
              return;
            }
            // Add the image to the map's style
            if (!mapRef.current!.hasImage("comment-icon")) {
              mapRef.current!.addImage("comment-icon", image!);
            }

            // Add a symbol layer to display the comment icon and comment count on buildings with commentsCount > 0
            mapRef.current!.addLayer({
              id: "building-comment-icons",
              type: "symbol",
              source: "clicked-buildings",
              filter: [">", ["get", "commentsCount"], 0],
              minzoom: 13,
              layout: {
                "icon-image": "comment-icon",
                "icon-size": 0.8,
                "icon-offset": [15, 0],
                "icon-allow-overlap": true,
                "text-field": ["get", "commentsCount"],
                "text-font": ["Open Sans Regular", "Arial Unicode MS Regular"],
                "text-size": 12,
                "text-offset": [1, -0.7],
                "text-anchor": "top",
                "text-allow-overlap": true
              },
              paint: {
                "text-color": "#202125"
              }
            });
          }
        );
      }
    })
    .catch(error => {
      console.error("Error fetching buildings:", error);
    });
};

export default BuildingMap;
