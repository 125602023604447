import React, { Component } from "react";
import { Col, Form } from "react-bootstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import "./SearchInputBox.scss";

class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: ""
    };
    this.searchInputRef = React.createRef();
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(async results => {
        const latLng = await getLatLng(results[0]);
        this.props.setMapCenter(latLng);
        if (results[0].address_components.length === 5) {
          this.props.openMiddleView();
        }
      })
      .catch(error => console.error("Error", error));
    this.searchInputRef.current.blur();
  };

  render() {
    const style = { backgroundColor: "#ffffff", cursor: "pointer" };
    return (
      <Form className="search-input">
        <Form.Row>
          <Col>
            <PlacesAutocomplete
              value={this.state.address}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <div>
                  <input
                    ref={this.searchInputRef}
                    {...getInputProps({
                      placeholder: "Talon osoite",
                      className: "location-search-input",
                      style
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          key={suggestion.placeId} // Use a unique identifier for the key
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Col>
        </Form.Row>
      </Form>
    );
  }
}

export default SearchInput;
