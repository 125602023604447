import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./mainMenu.scss";

class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainMenuVisibility: false
    };
  }

  render() {
    let wrapper = "wrapper";
    if (this.state.mainMenuVisibility) wrapper = wrapper + " open";

    return (
      <div>
        <Nav defaultActiveKey="/home" className={`${wrapper} flex-column`}>
          <div className="closeMenu" onClick={() => this.handleCloseClick()} />
          <Nav.Link
            className="menuElement"
            as={Link}
            to="/"
            onClick={() => this.handleCloseClick()}
          >
            Etusivu
          </Nav.Link>
          <Nav.Link
            className="menuElement"
            as={Link}
            to="/faq"
            onClick={() => this.handleCloseClick()}
          >
            Mikä Jaakko.com on?
          </Nav.Link>
          <Nav.Link
            className="menuElement"
            as={Link}
            to="/contact-us"
            onClick={() => this.handleCloseClick()}
          >
            Yhteystiedot
          </Nav.Link>
          <Nav.Link
            className="menuElement"
            as={Link}
            to="/terms-conditions"
            onClick={() => this.handleCloseClick()}
          >
            Käyttöehdot
          </Nav.Link>
          <Nav.Link
            className="menuElement"
            as={Link}
            to="/privacy-policy"
            onClick={() => this.handleCloseClick()}
          >
            Tietosuoja
          </Nav.Link>
          <Nav.Link
            className="menuElement"
            as={Link}
            to="/cookie-policy"
            onClick={() => this.handleCloseClick()}
          >
            Evästeet
          </Nav.Link>
        </Nav>
        <div
          className="menuBurger"
          onClick={() => this.handleOpenClick()}
        ></div>
      </div>
    );
  }

  handleOpenClick() {
    this.setState({
      mainMenuVisibility: true
    });
  }

  handleCloseClick() {
    this.setState({
      mainMenuVisibility: false
    });
  }
}

export default MainMenu;
