import React from "react";
import "./components.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import MainMenu from "../../menu/MainMenu";
import "./PolicyPage.scss";

const FAQ = () => {
  const history = useHistory();
  return (
    <Container className="p-0 policy-page-container">
      <MainMenu />
      <div className="closeMenuView" onClick={() => history.push("/")} />
      <div className="policy-page-content">
        <Link to="/">
          <div className="flatsonBlueText">Jaakko.com</div>
        </Link>
        <Row>
          <p className="page-name">Usein kysyttyä</p>
        </Row>
        <Row>
          <Col>
            <p className="small-text">
              <b className="paragraph-title">Mikä Jaakko.com on?</b>
              <br />
              Jaakko.com on palvelu, joka mahdollistaa avoimen keskustelun
              rakennuksista ja taloyhtiöistä. Käyttäjät – potentiaaliset
              ostajat, vuokralaiset, myyjät, omistajat ja muut kiinnostuneet –
              voivat käydä vuoropuhelua kenen tahansa kanssa mistä tahansa
              rakennuksesta. Palvelu auttaa erityisesti ostajia ja vuokralaisia
              ymmärtämään kohdetta paremmin, mutta myös omistajat ja asukkaat
              saavat arvokasta tietoa ja mahdollisuuden jakaa kokemuksiaan.
              <br />
              <br />
              Lisäksi palvelu tarjoaa mahdollisuuden tutustua taloyhtiöihin
              tarkemmin dokumenttien analysoinnin avulla. Käyttäjä voi ladata
              Jaakolle taloyhtiötä koskevat dokumentit, ja Jaakko nostaa niistä
              esiin olennaiset asiat, jotka auttavat tekemään perusteltuja
              päätöksiä. Jaakon tavoitteena on tarjota läpinäkyvä ja
              tasapuolinen käsitys kohteesta sekä mahdollistaa vertailu alueen
              muihin vastaaviin kohteisiin.
            </p>
            <p className="small-text">
              <b className="paragraph-title">Miten Jaakko.com toimii?</b>
              <br />
              Ensimmäisessä vaiheessa Jaakko auttaa ostajia ja muita
              kiinnostuneita käyttäjiä tutustumaan rakennuksiin ja taloyhtiöihin
              keskustelun ja dokumenttianalyysin avulla. Käyttäjät voivat
              esittää kysymyksiä, jakaa kokemuksia ja saada tietoa muilta
              palvelun käyttäjiltä.
              <br />
              <br />
              Jos sinulla on taloyhtiötä koskevia dokumentteja, kuten
              yhtiöjärjestys, tilinpäätös tai kunnossapitotarveselvitys, voit
              ladata ne analysoitavaksi. Jaakko nostaa dokumenteista esiin
              keskeiset asiat, joista kannattaa kysyä lisää tai jotka voivat
              vaikuttaa ostopäätökseen. Dokumenttianalyysi auttaa tunnistamaan
              esimerkiksi taloudellisia riskejä, tulevia remonttitarpeita tai
              muita merkittäviä tekijöitä.
            </p>
            <p className="small-text">
              <b className="paragraph-title">
                Miten Jaakon dokumenttianalyysi toimii?
              </b>
              <br />
              Kun lataat taloyhtiötä koskevat dokumentit, Jaakko analysoi ne ja
              tarjoaa tiivistetyt havainnot keskeisistä asioista, kuten
              taloyhtiön taloudesta, omistusrakenteesta ja remonttihistoriasta.
              Havaintoja verrataan alueen muihin kohteisiin, jotta saat kattavan
              kuvan. Dokumenttianalyysi tapahtuu reaaliaikaisesti ja tulokset
              ovat käytössä muutamassa minuutissa.
            </p>
            <p className="small-text">
              <b className="paragraph-title">
                Kuka Jaakon arviot tekee ja voiko arvioihin luottaa?
              </b>
              <br />
              Jaakon havainnoissa hyödynnetään generatiivista tekoälyä, mikä
              mahdollistaa analyysien kustannustehokkaan ja puolueettoman
              toteutuksen. Vaikka Jaakko on beta-vaiheessa ja virheitä voi
              esiintyä, tarjoaa se jo nyt erinomaista tukea ostopäätösten
              teossa. Tärkeiden asioiden tarkistus on kuitenkin aina käyttäjän
              vastuulla, joka viime kädessä tekee päätökset. Jaakon tavoitteena
              on nostaa esiin kaikki olennaiset tekijät, joista kannattaa kysyä
              lisää.
            </p>
            <p className="small-text">
              <b className="paragraph-title">
                Minkälaisia kohteita Jaakko kattaa?
              </b>
              <br />
              Jaakko kattaa kaikki Suomessa sijaitsevat asunto-osakeyhtiöt ja
              laajenee asteittain muihin kiinteistötyyppeihin sekä
              kansainvälisille markkinoille. Karttapohjainen käyttöliittymä
              mahdollistaa tutustumisen rakennuksiin ja keskustelun kaikkialla
              maailmassa.
            </p>
            <p className="small-text">
              <b className="paragraph-title">
                En ole juuri nyt ostamassa asuntoa, voinko silti käyttää
                palvelua?
              </b>
              <br />
              Ehdottomasti. Voit keskustella kiinnostavista rakennuksista, oppia
              lisää alueista ja tutustua taloyhtiöihin dokumenttianalyysin
              avulla. Palvelu tarjoaa arvokasta tietoa kaikille kiinteistöistä
              kiinnostuneille, olitpa ostaja, vuokralainen, omistaja tai muuten
              vain kiinnostunut.
            </p>
            <p className="small-text">
              <b className="paragraph-title">
                Voinko kiinnostua taloyhtiöstä, vaikka minulla ei olisikaan sen
                papereita?
              </b>
              <br />
              Kyllä. Voit tarkastella mahdollisesti saatavilla olevia
              perustietoja rakennuksesta, tykätä ja keskustella kiinnostavista
              kohteista sekä jakaa niitä.
            </p>
            <p className="small-text">
              <b className="paragraph-title">Miten osallistun keskusteluun?</b>
              <br />
              Keskustelu palvelussa on anonyymiä, mutta edellyttää
              rekisteröitymistä. Keskusteluissa tulee noudattaa hyviä tapoja,
              eikä niissä saa mainita omia tai muiden nimiä tai muita
              yhteystietoja. Lisäksi keskusteluissa ei saa esittää loukkaavia,
              laittomia, häiritseviä tai valheellisia väitteitä, eikä mitään
              sisältöä, joka rikkoo lakeja tai hyviä tapoja.
            </p>
            <p className="small-text">
              <b className="paragraph-title">
                Ovatko taloyhtiödokumentit julkisia, eli voin jakaa ne Jaakolle?
              </b>
              <br />
              Jaakko auttaa analysoimaan esimerkiksi välittäjältä saadut
              dokumentit asunnon ostajan puolesta, eli niiden jakaminen on
              mahdollista. Käyttäjän lataamia tietoja esitetään muille
              käyttäjille yleisemmällä tasolla ja osana vertailuryhmiä –
              tarkemmat analyysit ja muut myöhemmin julkaistavat lisäpalvelut
              ovat saatavilla vain dokumenttien lataajalle.
            </p>
            <p className="small-text">
              <b className="paragraph-title">Maksullisuus</b>
              <br />
              Palvelun perustoiminnot ovat aluksi veloituksettomia, mutta
              laajemmista analyyseistä ja lisäpalveluista voidaan
              tulevaisuudessa periä maksu. Palvelun veloitukseton testikäyttö ei
              velvoita jatkossa mihinkään.
            </p>
            <p className="small-text">
              <b className="paragraph-title">
                Miten voin antaa palautetta palvelusta?
              </b>
              <br />
              Kaikki palaute on tervetullutta. Voit lähettää palautteesi
              osoitteeseen support@jaakko.com. Kehitämme palvelua jatkuvasti ja
              arvostamme käyttäjien näkemyksiä.
            </p>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default FAQ;
