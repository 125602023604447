import React from "react";
import { connect } from "react-redux";
import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AppContent from "./components/AppContent";
import About from "./components/common/about";
import Contact from "./components/common/contact";
import FAQ from "./components/common/main-menu-components/FAQ";
import ContactUs from "./components/common/main-menu-components/ContactUs";
import TermsConditions from "./components/common/main-menu-components/TermsConditions";
import fetchApartments from "./api/apartments";
import {
  toggleLike,
  selectBuilding,
  selectApartment,
  setMapCenter,
  likeApartment
} from "./state/actions/Actions";
import PrivacyPolicy from "./components/common/main-menu-components/PrivacyPolicy";
import CookiePolicy from "./components/common/main-menu-components/CookiePolicy";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buildingFullVisible: false,
      divStyle: { height: window.innerHeight, width: window.innerWidth }
    };
  }

  render() {
    const {
      buildings,
      error,
      toggleLike,
      selectBuilding,
      selectedBuilding,
      selectApartment,
      selectedApartment,
      mapCenter,
      selectedBuildingIndex,
      selectedBuildList,
      setMapCenter,
      likeApartment
    } = this.props;

    return (
      <div className="App-wrapper">
        {/* style={this.state.divStyle} */}
        <Router>
          <div className="App-content">
            <Switch>
              <Route
                exact
                path="/"
                render={props => (
                  <AppContent
                    {...props}
                    buildings={buildings}
                    error={error}
                    toggleLike={toggleLike}
                    selectBuilding={selectBuilding}
                    selectedBuilding={selectedBuilding}
                    selectedApartment={selectedApartment}
                    selectApartment={selectApartment}
                    mapCenter={mapCenter}
                    selectedBuildingIndex={selectedBuildingIndex}
                    selectedBuildList={selectedBuildList}
                    setMapCenter={setMapCenter}
                    likeApartment={likeApartment}
                    toggleFullVisibility={this.toggleFullVisibility}
                    buildingFullVisible={this.state.buildingFullVisible}
                  />
                )}
              />
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/faq" component={FAQ} />
              <Route path="/contact-us" component={ContactUs} />
              <Route path="/terms-conditions" component={TermsConditions} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/cookie-policy" component={CookiePolicy} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }

  handleResize = e => {
    let windowheight = window.innerHeight;

    let divStyle = {
      height: windowheight,
      width: window.innerWidth
    };

    this.setState({ divStyle });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  UNSAFE_componentWillMount = () => {
    this.props.fetchApartments();
  };

  toggleFullVisibility = () => {
    this.setState({
      buildingFullVisible: !this.state.buildingFullVisible
    });
  };
}

const mapStateToProps = state => ({
  buildings: state.buildings.buildings,
  pending: state.buildings.pending,
  error: state.buildings.error,
  selectedBuilding: state.buildings.selectedBuilding,
  selectedApartment: state.buildings.selectedApartment,
  mapCenter: state.buildings.mapCenter,
  selectedBuildingIndex: state.buildings.selectedBuildingIndex,
  selectedBuildList: state.buildings.selectedBuildList
});

const mapDispatchToProps = dispatch => ({
  fetchApartments: () => dispatch(fetchApartments()),
  toggleLike: () => dispatch(toggleLike()),
  selectBuilding: address => dispatch(selectBuilding(address)),
  selectApartment: id => dispatch(selectApartment(id)),
  setMapCenter: coordinates => dispatch(setMapCenter(coordinates)),
  likeApartment: index => dispatch(likeApartment(index))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
