import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import FilesDropBox, { DocumentAndFileName } from "./FilesDropBox";
import LoadingSpinner from "../common/LoadingSpinner";

type DocumentDropzoneProps = {
  documentsAndFileNames: DocumentAndFileName[];
  setDocumentsAndFileNames: (files: DocumentAndFileName[]) => void;
  handleSubmitReportDocuments: () => void;
  isUploading: boolean;
};

const DocumentDropzone: React.FC<DocumentDropzoneProps> = ({
  documentsAndFileNames,
  setDocumentsAndFileNames,
  handleSubmitReportDocuments,
  isUploading
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const toggleExpansion = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <div className="report__dropzone-wrapper">
      {/* <div>
        <button onClick={toggleExpansion} className="report__dropzone-toggle">
          {isExpanded ? "Piilota" : "Lataa dokumentteja"}
          <span
            className={`report__dropzone-toggle__chevron${
              isExpanded ? " report__dropzone-toggle__chevron--up" : ""
            }`}
          >
            {">"}
          </span>
        </button>
      </div> */}
      <CSSTransition
        in={isExpanded}
        timeout={600}
        classNames="dropzone"
        unmountOnExit
      >
        <div className="report__dropzone-content">
          <FilesDropBox
            documentsAndFileNames={documentsAndFileNames}
            setDocumentsAndFileNames={setDocumentsAndFileNames}
          />
          <div className="report__submit-button-wrapper">
            <div className="report__submit-button-wrapper__text">
              Lähettämällä dokumentteja sitoudut noudattamaan palveluidemme{" "}
              <a
                href="/terms-conditions"
                target="_blank"
                rel="noreferrer"
                className="report__submit-button-wrapper__text__link"
              >
                käyttöehtoja
              </a>
              , sekä ilmoitat lukeneesi ja hyväksyväsi Jaakkocom Oy:n{" "}
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="report__submit-button-wrapper__text__link"
              >
                yleisen tietosuojakuvauksen
              </a>
              .
            </div>
            {isUploading && (
              <div className="report__submit-button-wrapper__loading">
                <div className="report__submit-button-wrapper__loading-spinner">
                  <LoadingSpinner />
                </div>
                <div>
                  <div>Koostan raporttia tietojen pohjalta.</div>
                  <div className="report__submit-button-wrapper__loading-text">
                    Tässä voi mennä muutama minuutti...
                  </div>
                </div>
              </div>
            )}
            {!isUploading && (
              <button
                onClick={handleSubmitReportDocuments}
                className="report__submit-button-wrapper__submit-button"
                disabled={isUploading || documentsAndFileNames.length === 0}
              >
                {isUploading ? <LoadingSpinner /> : "Analysoi"}
              </button>
            )}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default DocumentDropzone;
