import React, { DragEvent, useState, useRef } from "react";
import "./Report.scss";

export type DocumentAndFileName = {
  document: string;
  fileName: string;
};

type Props = {
  documentsAndFileNames: DocumentAndFileName[];
  setDocumentsAndFileNames: React.Dispatch<
    React.SetStateAction<DocumentAndFileName[]>
  >;
};

const FilesDropBox = (props: Props) => {
  const { documentsAndFileNames, setDocumentsAndFileNames } = props;
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (files) {
      Array.from(files).forEach(file => processFile(file));
    }
  }

  function handleDragOver(event: DragEvent<HTMLDivElement>): void {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = "copy";
    setIsDragging(true);
  }

  function handleDragLeave(event: DragEvent<HTMLDivElement>): void {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  }

  function handleFileDrop(event: React.DragEvent<HTMLDivElement>): void {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const files = event.dataTransfer.files;
    if (files) {
      Array.from(files).forEach(file => processFile(file));
    }
  }

  function processFile(file: File) {
    if (file) {
      encodeFileToBase64(file)
        .then(base64data => {
          setDocumentsAndFileNames(prev => [
            ...prev,
            { document: base64data, fileName: file.name }
          ]);
        })
        .catch(error => {
          console.error("Error encoding file to base64:", error);
        });
    }
  }

  function encodeFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = (reader.result as string).split(",")[1];
        resolve(base64String);
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }

  function handleUploadFileClick() {
    fileInputRef.current?.click();
  }

  return (
    <>
      <div className="report__content__sub-title">Lataa dokumentit</div>
      <div
        className={`report__dropzone ${
          isDragging ? "report__dropzone--dragging" : ""
        }`}
        onDrop={handleFileDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onClick={handleUploadFileClick}
      >
        <img src="/assets/upload.svg" alt="Upload PDF" height="42px" />
        <input
          type="file"
          accept=".pdf"
          multiple
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <div className="report__dropzone__text">
          {documentsAndFileNames.length > 0 ? (
            documentsAndFileNames.map(documentAndFileName => (
              <div key={documentAndFileName.fileName}>
                {documentAndFileName.fileName}
              </div>
            ))
          ) : (
            <>
              <span className="report__dropzone__clickable-text">
                {/* <u>Click to upload</u> */}
                <u>Valitse tiedosto</u>
              </span>
              {/* <span> or drag and drop</span> */}
              <span> tai pudota tähän</span>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FilesDropBox;
