import { getCenterMapBuildings, curDay } from "../../components/common/mappers";

const buildingsReducer = (state = {}, action) => {
  switch (action.type) {
    case "FETCH_APARTMENTS_PENDING":
      return {
        ...state,
        pending: true
      };

    case "FETCH_APARTMENTS_SUCCESS":
      let likedApartments =
        JSON.parse(localStorage.getItem("likedApartments")) || [];
      const buildingsWithApartments = state.buildings.map(building => {
        const { katunimi_suomi, osoitenumero } = building.properties;
        const buildingAddress = `${katunimi_suomi}|${osoitenumero}`;
        building.apartments = action.payload.apartments[buildingAddress] || [];
        building.apartments.forEach((apartment, index) => {
          if (apartment["Agency url"].length > 2) {
            apartment.Claimed = "X";
            apartment.Status = "Actively selling";
            apartment.Date = curDay(".");
            // claimedDB.claim({apartment});
          }
          apartment.id = index;
          apartment.like = false;
          likedApartments.forEach(localApart => {
            if (localApart.Id === apartment.Id) apartment.like = true;
          });
        });
        return building;
      });
      return {
        ...state,
        buildings: buildingsWithApartments,
        pending: false
      };

    case "FETCH_APARTMENTS_ERROR":
      return {
        ...state,
        pending: false,
        error: action.error
      };

    case "SET_MAP_CENTER":
      const coordinates = action.payload.coordinates;
      return {
        ...state,
        mapCenter: coordinates
      };

    case "SELECT_BUILDING":
      const search = action.payload.address;
      const buildings = state.buildings;
      let selectedBuilding = null;
      let selectedBuildingIndex = -1;
      let selectedBuildList = [];

      if (search !== null) {
        selectedBuildingIndex = buildings.findIndex(building => {
          return (
            search === building.properties.address_suomi ||
            search === building.properties.address_ruotsi
          );
        });

        // Ensure the index is valid
        if (selectedBuildingIndex !== -1) {
          selectedBuilding = buildings[selectedBuildingIndex];

          selectedBuildList = buildings.filter(
            building =>
              search === building.properties.address_suomi ||
              search === building.properties.address_ruotsi
          );
        } else {
          console.warn(`No building found for address: ${search}`);
        }
      }

      // Validate selectedBuildList before calculating mapCenter
      let mapCenter = state.mapCenter; // Default to current mapCenter
      if (selectedBuildList && selectedBuildList.length > 0) {
        const calculatedCenter = getCenterMapBuildings(selectedBuildList);

        // Ensure calculatedCenter has valid lat and lng
        if (
          calculatedCenter &&
          typeof calculatedCenter.lat === "number" &&
          typeof calculatedCenter.lng === "number" &&
          !isNaN(calculatedCenter.lat) &&
          !isNaN(calculatedCenter.lng)
        ) {
          mapCenter = calculatedCenter;
        } else {
          console.error("Invalid mapCenter calculated:", calculatedCenter);
        }
      } else {
        console.warn("selectedBuildList is empty or invalid.");
      }

      return {
        ...state,
        selectedBuilding,
        selectedBuildingIndex,
        selectedBuildList,
        mapCenter
      };

    case "TOGGLE_LIKE":
      selectedBuilding = state.buildings.find(
        building =>
          building.properties.id === state.selectedBuilding.properties.id
      );
      selectedBuilding.like = !selectedBuilding.like;
      let likedBuildings = state.buildings.filter(building => building.like);
      window.localStorage.setItem(
        "likedBuildings",
        JSON.stringify(likedBuildings)
      );
      return {
        ...state,
        buildings: state.buildings.slice(),
        selectedBuilding: { ...state.selectedBuilding }
      };

    case "LIKE_APARTMENT":
      const index = action.payload.index;
      state.selectedBuilding.apartments[index].like = true;
      let Apartments = [];
      state.buildings.forEach(building => {
        building.apartments.forEach(apartment => {
          if (apartment.like) Apartments.push(apartment);
        });
      });

      window.localStorage.setItem(
        "likedApartments",
        JSON.stringify(Apartments)
      );
      return {
        ...state,
        buildings: state.buildings.slice(),
        selectedBuilding: { ...state.selectedBuilding }
      };

    case "SELECT_APARTMENT":
      const id = action.payload.id;
      let selectedApartment;
      if (id === null) {
        selectedApartment = null;
      } else {
        selectedApartment = state.selectedBuilding.apartments.find(
          apartment => id === apartment.Id
        );
      }
      return {
        ...state,
        selectedApartment
      };

    default:
      return state;
  }
};

export default buildingsReducer;
