import { getCookie } from "../AppContent";
import {
  ArticlesOfAssociationApartments,
  ArticlesOfAssociationBaseInfo,
  ArticlesOfAssociationCommercialSpaces
} from "../HouseMap/types";
import { BuildingWithConvertedAddresses } from "../HouseMap/types";

interface FetchExistingReportProps {
  selectedBuilding: BuildingWithConvertedAddresses;
  setAnalyzedArticlesOfAssociationBaseInfo: (
    info: ArticlesOfAssociationBaseInfo | null
  ) => void;
  setAnalyzedArticlesOfAssociation: (data: string | null) => void;
  setAnalyzedArticlesOfAssociationApartments: (
    data: ArticlesOfAssociationApartments | null
  ) => void;
  setAnalyzedArticlesOfAssociationCommercialSpaces: (
    data: ArticlesOfAssociationCommercialSpaces | null
  ) => void;
  setAnalyzedFinancialStatement: (data: string | null) => void;
  setAnalyzedMaintenanceNeedsAssessment: (data: string | null) => void;
  setIsLoadingExistingReport: (isLoading: boolean) => void;
  setLikeCount: (likeCount: number) => void;
  setUserHasLiked: (userHasLiked: boolean) => void;
  setEmptyReport: (data: any) => void;
  setIsLoadingEmptyReport: (isLoading: boolean) => void;
}

export const fetchExistingReport = ({
  selectedBuilding,
  setAnalyzedArticlesOfAssociationBaseInfo,
  setAnalyzedArticlesOfAssociation,
  setAnalyzedArticlesOfAssociationApartments,
  setAnalyzedArticlesOfAssociationCommercialSpaces,
  setAnalyzedFinancialStatement,
  setAnalyzedMaintenanceNeedsAssessment,
  setLikeCount,
  setUserHasLiked,
  setIsLoadingExistingReport,
  setIsLoadingEmptyReport,
  setEmptyReport
}: FetchExistingReportProps) => {
  setIsLoadingExistingReport(true);

  fetch(
    `${process.env.REACT_APP_API_URL}/report?building-id=${selectedBuilding.id}`,
    {
      credentials: "include",
      headers: {
        "X-CSRF-TOKEN": getCookie("csrf_access_token")
      }
    }
  )
    .then(res => {
      if (res.status === 404) {
        setIsLoadingEmptyReport(true);
        fetchEmptyReport(
          selectedBuilding,
          setEmptyReport,
          setIsLoadingEmptyReport,
          setIsLoadingExistingReport,
          setLikeCount,
          setUserHasLiked
        );
        return null;
      }
      return res.json();
    })
    .then(res => {
      // Building exists but no report
      if (
        res &&
        res.data &&
        (res.data.report_is_empty || res.data.properties.report_is_empty)
      ) {
        setIsLoadingEmptyReport(true);
        fetchEmptyReport(
          selectedBuilding,
          setEmptyReport,
          setIsLoadingEmptyReport,
          setIsLoadingExistingReport,
          setLikeCount,
          setUserHasLiked
        );
        return null;
      }
      return res;
    })
    .then(res => {
      if (res && res.data) {
        const data = res.data;
        setAnalyzedArticlesOfAssociationBaseInfo(
          data.articles_of_association_base_info
        );
        setAnalyzedArticlesOfAssociation(data.articles_of_association);
        setAnalyzedArticlesOfAssociationApartments(
          data.articles_of_association_apartments
        );
        setAnalyzedArticlesOfAssociationCommercialSpaces(
          data.articles_of_association_commercial_spaces
        );
        setAnalyzedFinancialStatement(data.financial_statement);
        setAnalyzedMaintenanceNeedsAssessment(
          data.maintenance_needs_assessment
        );
        if (data.empty_report && data.empty_report.analyysi) {
          setEmptyReport(data.empty_report);
        }
      }
      setIsLoadingExistingReport(false);
    })
    .catch(error => {
      console.error("Error fetching existing report:", error);
      setIsLoadingExistingReport(false);
    });
};

export const fetchEmptyReport = (
  selectedBuilding: BuildingWithConvertedAddresses,
  setEmptyReport: (data: any) => void,
  setIsLoadingEmptyReport: (isLoading: boolean) => void,
  setIsLoadingExistingReport: (isLoading: boolean) => void,
  setLikeCount: (likeCount: number) => void,
  setUserHasLiked: (userHasLiked: boolean) => void
) => {
  setIsLoadingEmptyReport(true);
  fetch(`${process.env.REACT_APP_API_URL}/generate-empty-report`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": getCookie("csrf_access_token")
    },
    body: JSON.stringify({ selected_building: selectedBuilding })
  })
    .then(response => response.json())
    .then(res => {
      if (res.empty_report) {
        setEmptyReport(res.empty_report);
        setLikeCount(res.properties.like_count);
        setUserHasLiked(res.properties.user_has_liked);
      }
    })
    .catch(error => {
      console.error("Error fetching empty report:", error);
    })
    .finally(() => {
      setIsLoadingEmptyReport(false);
      setIsLoadingExistingReport(false);
    });
};
