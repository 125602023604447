import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import "../App.scss";
import SearchInput from "./search/SearchInput";
import fetchApartments from "../api/apartments";
import MainMenu from "./menu/MainMenu";
import SignupLogin from "./SignupLogin/SignupLogin";
import Report from "./Report/Report";
import { BuildingWithConvertedAddresses, LatLng } from "./HouseMap/types";
import SignupLoginModal from "./SignupLoginModal/SignupLoginModal";
import BuildingMap from "./HouseMap/BuildingMap";

// Utility function to get a cookie by name
export function getCookie(name: string) {
  const cookieStr = document.cookie;
  const cookies = cookieStr.split(";");
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
}

const AppContent = () => {
  const [reportVisible, setReportVisible] = useState(false);
  const [
    selectedBuilding,
    setSelectedBuilding
  ] = useState<BuildingWithConvertedAddresses | null>(null);
  const [mapCenter, setMapCenter] = useState<LatLng>({
    lat: 60.1584,
    lng: 24.949
  }); // Default to Helsinki coordinates
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const refreshTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isRefreshingRef = useRef(false);
  const [expiresAt, setExpiresAt] = useState<number | null>(null);

  // Function to refresh the token
  const refreshToken = useCallback(async () => {
    if (isRefreshingRef.current) return;
    isRefreshingRef.current = true;
    try {
      const csrfToken = getCookie("csrf_refresh_token");
      if (!csrfToken) {
        console.error("CSRF token not found");
        setIsLoggedIn(false);
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/refresh-token`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "X-CSRF-TOKEN": csrfToken
          }
        }
      );
      if (response.ok) {
        const data = await response.json();
        setIsLoggedIn(true);
        const newExpiresAt = Date.now() + data.expires_in * 1000;
        setExpiresAt(newExpiresAt); // Update expiresAt to schedule next refresh
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      setIsLoggedIn(false);
    } finally {
      isRefreshingRef.current = false;
    }
  }, []);

  // Effect to schedule token refresh when expiresAt changes
  useEffect(() => {
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
    }

    if (expiresAt != null) {
      const minRefreshInterval = 60 * 1000; // 60 seconds in milliseconds
      const timeUntilExpiry = expiresAt - Date.now();

      if (timeUntilExpiry > minRefreshInterval) {
        const refreshTime = timeUntilExpiry - minRefreshInterval;
        refreshTimeoutRef.current = setTimeout(() => {
          refreshToken();
        }, refreshTime);
      } else if (timeUntilExpiry > 0) {
        console.warn("Token expires soon; refreshing token immediately.");
        refreshToken();
      } else {
        console.warn("Token has expired; user will be logged out.");
        setIsLoggedIn(false);
      }
    }

    return () => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
  }, [expiresAt, refreshToken]);

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/check-login-status`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "X-CSRF-TOKEN": getCookie("csrf_access_token")
            }
          }
        );
        const data = await response.json();
        setIsLoggedIn(data.isLoggedIn);
        if (data.isLoggedIn && data.expires_in) {
          const newExpiresAt = Date.now() + data.expires_in * 1000;
          setExpiresAt(newExpiresAt); // Update expiresAt to schedule next refresh
        }
      } catch (error) {
        console.error("Error checking login status:", error);
        setIsLoggedIn(false);
      }
    };

    checkLoginStatus();

    return () => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
  }, []);

  const toggleReportVisible = useCallback(() => {
    setReportVisible(prev => !prev);
  }, []);

  const selectBuilding = useCallback(
    building => {
      if (building.centroid) {
        setMapCenter({
          lat: building.centroid.lat,
          lng: building.centroid.lng
        });
      }
      setSelectedBuilding(building);
      toggleReportVisible();
    },
    [setMapCenter, setSelectedBuilding, toggleReportVisible]
  );

  // Modify handleLoginSuccess to accept expires_in and set expiresAt
  const handleLoginSuccess = useCallback((expiresIn: number) => {
    setIsLoggedIn(true);
    const newExpiresAt = Date.now() + expiresIn * 1000;
    setExpiresAt(newExpiresAt); // Update expiresAt to schedule next refresh
  }, []);

  // Check for 'building-id' in the URL on component mount
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const buildingId = params.get("building-id");
    if (buildingId) {
      // Fetch the building data using the buildingId
      fetch(
        `${process.env.REACT_APP_API_URL}/report?building-id=${buildingId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "X-CSRF-TOKEN": getCookie("csrf_access_token")
          }
        }
      )
        .then(response => response.json())
        .then(data => {
          if (data && data.data) {
            setSelectedBuilding(data.data);
            setReportVisible(true);
          } else {
            console.error("No data found for the given building ID");
          }
        })
        .catch(error => {
          console.error("Error fetching building by ID:", error);
        });
    }
  }, []);

  // Update the URL when the report visibility or selected building changes
  useEffect(() => {
    const url = new URL(window.location.href);
    if (selectedBuilding && reportVisible) {
      // Add 'building-id' to the URL
      url.searchParams.set("building-id", selectedBuilding.id);
      window.history.pushState({}, "", url.toString());
    } else {
      // Remove 'building-id' from the URL
      url.searchParams.delete("building-id");
      window.history.pushState({}, "", url.toString());
    }
  }, [selectedBuilding, reportVisible]);

  return (
    <div>
      <div className="p-0">
        <MainMenu />
        <SignupLogin
          toggleLoginModal={() => setLoginModalVisible(true)}
          isLoggedIn={isLoggedIn}
        />
        {loginModalVisible && (
          <SignupLoginModal
            toggleSignupLoginModalVisible={() => setLoginModalVisible(false)}
            setIsLoggedIn={setIsLoggedIn}
            isLoggedIn={isLoggedIn}
            onLoginSuccess={handleLoginSuccess}
          />
        )}
        <Row className="App-header-row">
          <Col className="App-header">
            <Row>
              <img src="/logo_same_size.png" alt="Logo" height="62" />
            </Row>
          </Col>
        </Row>
        <div className="app-content-wrapper">
          <Row className="p-0 m-0">
            <BuildingMap
              mapCenter={mapCenter}
              selectedBuilding={selectedBuilding}
              setMapCenter={setMapCenter}
              selectBuilding={selectBuilding}
            />
          </Row>
        </div>
        <SearchInput
          setMapCenter={setMapCenter}
          selectBuilding={selectBuilding}
          selectedBuilding={selectedBuilding}
          openMiddleView={() => {}}
        />
        {selectedBuilding && reportVisible && (
          <Report
            isLoggedIn={isLoggedIn}
            selectedBuilding={selectedBuilding}
            toggleReportVisible={toggleReportVisible}
            setSelectedBuilding={setSelectedBuilding}
            setLoginModalVisible={setLoginModalVisible}
          />
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchApartments: () => dispatch(fetchApartments())
});

export default connect(null, mapDispatchToProps)(AppContent);
