import React from "react";
import "./LoadingExistingReportSkeleton.scss";

const LoadingExistingReportSkeleton = () => {
  return (
    <div className="loading-existing-report-skeleton">
      <div className="loading-existing-report-skeleton__property">
        <div className="loading-existing-report-skeleton__property__header"></div>
        <div className="loading-existing-report-skeleton__line"></div>
      </div>
      <div className="loading-existing-report-skeleton__content">
        <div className="loading-existing-report-skeleton__content__sub-section">
          <div className="loading-existing-report-skeleton__line"></div>
          <div className="loading-existing-report-skeleton__line"></div>
        </div>
        <div className="loading-existing-report-skeleton__content__sub-section">
          <div className="loading-existing-report-skeleton__line"></div>
          <div className="loading-existing-report-skeleton__line"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingExistingReportSkeleton;
